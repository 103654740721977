import axios from 'axios'
import errorParser from '@/shared/utils/ErrorParser'

export default {
    /**
     * Logout current user
     */
    logout() {
        return new Promise((resolve, reject) => {
            axios.post('/logout').then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Password change
     */
    passwordChange(data) {
        return new Promise((resolve, reject) => {
            axios.post('/user/password', data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },


    /**
     * Update user
     */
    userChange(data) {
        return new Promise((resolve, reject) => {
            axios.post('/user/update', data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Create user
     */
    newUser(data) {
        return new Promise((resolve, reject) => {
            axios.post('/user/new', data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Delete user
     */
    deleteUser(user) {
        return new Promise((resolve, reject) => {
            axios.delete('/user/'+user.userId).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Get current user data
     */
    getUser() {
        return new Promise((resolve, reject) => {
            axios.get('/user').then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Get long current user data
     */
    getUserLong() {
        return new Promise((resolve, reject) => {
            axios.get('/user/long').then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Generates random password for policy:
     * - at least 12 characters 
     * - at least 1 upper-case letter 
     * - at least 1 lower-case letter 
     * - at least 1 number 
     * - at least 1 special character 
     */
    getRandomPassword() {
        var numberChars = "0123456789";
        var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lowerChars = "abcdefghijklmnopqrstuvwxyz";
        var specialChars = "!@#$%^&*()";
        var allChars = numberChars + upperChars + lowerChars + specialChars;
        var randPasswordArray = Array(12);

        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray[3] = specialChars;
        randPasswordArray = randPasswordArray.fill(allChars, 4);

        return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
    },

    /**
     * Shufles array
     * @param {Array} array array to shuffle
     */
    shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
      }

}
