<template>
    <page-layout :loading="isLoading">
        <template v-if="user.userRoles['signatory']">
            <b-row class="align-items-center">
                <h1 class="col-md-auto">
                    Festgeld

                    <b-dropdown dropright size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                            <i class="fas fa-share-square"></i>
                        </template>

                        <b-dropdown-item :href="api+'/csv/deposits/open'">CSV aktive Festgelder</b-dropdown-item>
                        <b-dropdown-item :href="api+'/csv/deposits/all'">CSV alle Festgelder</b-dropdown-item>
                    </b-dropdown>
                </h1>
            </b-row>

            <b-row>
                <div class="col-lg-6">
                    <b-card>
                        <b-row class="align-items-center">
                            <div class="col-sm-6">Möchten Sie in ein<br>Festgeld veranlagen?</div>
                            <div class="col-sm-6 mt-2 mt-sm-0">
                                <b-button :to="{ name: 'deposit-new'}" size="sm" variant="primary" class="w-100">NEUES FESTGELD</b-button>
                            </div>
                        </b-row>
                    </b-card>
                </div>
            </b-row>
        </template>
        <b-row v-else>
            <h1 class="col-12">Festgeld online beantragen</h1>
            <div class="col-12"> 
                <div class="card px">
                    Sie sind derzeit nicht für KOMMUNALKREDIT DIREKT Festgelder registriert. Möchten Sie jetzt ein Festgeld beantragen?<br>
                    Das ist ganz einfach über unsere Online-Antragsstrecke möglich:
                    <b-button :href="registrationURL" target="_new" size="sm" variant="primary" class="mt-2 btn-2">FESTGELD BEANTRAGEN</b-button>
                </div>
            </div>
        </b-row>

        <div v-for="data in list" :key="data.number">
            
            <b-row class="mt-3">
                <h2 class="col-12">{{data.number}}</h2>
            </b-row>

            <div class="row cards">
                <div class="card-col col-sm-6 col-lg order-1">
                    <div class="card">
                        <div class="card-body align-items-center d-flex justify-content-center">
                            <div class="text-center">
                                <big-amount :amount="data['SAPTerminEL - Nominale']" />
                                <b>fällig am {{data['SAPTerminEL - Laufzeitende'] | formatDate}}</b>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-col col-sm-12 col-lg-6 col-xl-7 order-2 order-sm-3 order-lg-2">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <h2 class="col-md-12">
                                    {{data.description}}
                                    <a href="#" @click.prevent="editDescription(data)" ><i class="fas fa-edit"></i></a>
                                </h2>

                                <div class="col-md-4">
                                    <div class="label">Zinssatz</div><b>{{data['SAPTerminEL - Zinssatz'] | formatNumber(3)}} % p.a.</b>
                                </div>


                                <div class="col-md-4">
                                    <div class="label">Laufzeit</div><b>{{data['SAPTerminEL - Laufzeit']}}</b>
                                </div>

                                
                                <div class="col-md-4">
                                    <div class="label">Laufzeitbeginn</div><b>{{data['SAPTerminEL - Laufzeitbeginn'] | formatDate}}</b>
                                </div>
                            </b-row>

                            <b-row class="mt-2 align-items-center">
                                <div class="col-md-4">
                                    <div class="label">Auszahlung</div><b>{{data['SAPTerminEL - Auszahlungsbetr.'] | formatNumber}} EUR</b>
                                </div>

                                <div class="col-md-4">
                                    <div class="label">KESt berücksichtigt</div><b>{{data['kest']}}</b>
                                </div>
                            </b-row>
                        </div>
                    </div>
                </div>

                <div class="card-col col-sm-6 col-lg order-3 order-sm-2 order-lg-3">
                    <div class="card" :class="{'bg-secondary': isDisabled(data)}" >
                        <div class="card-body align-items-center d-flex">
                            <b-row>
                                <template v-if="eye4 && extended(data) && storno(data)"><!-- BESTÄTIGEN -->
                                    <small class="col-md-12 small-text">
                                        <b>Bitte bestätigen Sie das Storno der Wiederveranlagung bis {{data['SAPTerminEL - Laufzeitende'] | formatDateAddDays(-3)}} im 4-Augen-Prinzip.</b>
                                        <info-button v-if="!isDisabled(data)" >
                                            Bitte beachten Sie, dass nur Zeichnungsberechtigte das Storno der Wiederveranlagung bestätigen können.
                                        </info-button>
                                    </small>
                                    <div class="col-md-12 mt-2">
                                        <b-button v-if="!isDisabled(data)" :to="{ name: 'deposit-storno-4', params: {id: data.egoId}}" size="sm" variant="primary" class="w-100">BESTÄTIGEN</b-button>
                                        <template v-else>
                                            <b-button id="BESTÄTIGEN" size="sm" variant="secondary" class="w-100">BESTÄTIGEN</b-button>
                                            <b-popover v-if="isExpired(data)" target="BESTÄTIGEN" triggers="hover" placement="top">
                                                Die Bestätigung des Stornos der Wiederveranlagung ist online leider nicht mehr möglich. Bitte wenden Sie sich an unser Kundenservice.
                                            </b-popover>
                                            <b-popover v-else-if="sameUser(data)" target="BESTÄTIGEN" triggers="hover" placement="top">
                                                Bitte beachten Sie, dass die Stornierung der Wiederveranlagung des Festgeldes erst durch die Freigabe eines zweiten Zeichnungsberechtigten wirksam wird.
                                            </b-popover>
                                            <b-popover v-else target="BESTÄTIGEN" triggers="hover" placement="top">
                                                Bitte beachten Sie, dass nur Zeichnungsberechtigte das Storno der Wiederveranlagung bestätigen können.
                                            </b-popover>
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="extended(data)"><!-- STORNIEREN -->
                                    <small class="col-md-12 small-text">
                                        <b>Zeichnungsberechtigte können die beauftragte Wiederveranlagung bis {{data['SAPTerminEL - Laufzeitende'] | formatDateAddDays(-3)}} stornieren.</b>
                                        <info-button v-if="!isDisabled(data)">
                                            Bitte beachten Sie, dass Sie für das Storno einer automatischen Wiederveranlagung
                                            am Konto zeichnungsberechtigt sein müssen.
                                        </info-button>
                                    </small>
                                    <div class="col-md-12 mt-2">
                                        <b-button v-if="!isDisabled(data)" :to="{ name: 'deposit-storno', params: {id: data.egoId}}" size="sm" variant="primary" class="w-100">STORNIEREN</b-button>
                                        <template v-else>
                                            <b-button id="STORNIEREN" size="sm" variant="secondary" class="w-100">STORNIEREN</b-button>
                                            <b-popover v-if="isExpired(data)" target="STORNIEREN" triggers="hover" placement="top">
                                                Das Storno der Wiederveranlagung ist online leider nicht mehr möglich. Bitte wenden Sie sich an unser Kundenservice.
                                            </b-popover>
                                            <b-popover v-else target="STORNIEREN" triggers="hover" placement="top">
                                                Bitte beachten Sie, dass nur Zeichnungsberechtigte die Wiederveranlagung stornieren können.
                                            </b-popover>
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="eye4 && reinvest(data)"><!-- FREIGEBEN -->
                                    <small class="col-md-12 small-text">
                                        <b>Die Wiederveranlagung muss bitte bis {{data['SAPTerminEL - Laufzeitende'] | formatDateAddDays(-3)}} im 4-Augen-Prinzip bestätigt werden.</b>
                                        <info-button v-if="!isDisabled(data)" >
                                            Bitte beachten Sie, dass nur Zeichnungsberechtigte die Wiederveranlagung bestätigen können.
                                        </info-button>
                                    </small>
                                    <div class="col-md-12 mt-2">
                                        <b-button v-if="!isDisabled(data)" :to="{ name: 'deposit-release', params: {id: data.egoId}}" size="sm" variant="primary" class="w-100">FREIGEBEN</b-button>
                                        <template v-else>
                                            <b-button id="FREIGEBEN" size="sm" variant="secondary" class="w-100">FREIGEBEN</b-button>
                                            <b-popover v-if="isExpired(data)" target="FREIGEBEN" triggers="hover" placement="top">
                                                Die Bestätigung der Wiederveranlagung ist online leider nicht mehr möglich. Bitte wenden Sie sich an unser Kundenservice.
                                            </b-popover>
                                            <b-popover v-else-if="sameUser(data)" target="FREIGEBEN" triggers="hover" placement="top">
                                                Bitte beachten Sie, dass die Wiederveranlagung des Festgeldes erst durch die Freigabe eines zweiten Zeichnungsberechtigten wirksam wird.
                                            </b-popover>
                                            <b-popover v-else target="FREIGEBEN" triggers="hover" placement="top">
                                                Bitte beachten Sie, dass nur Zeichnungsberechtigte die Wiederveranlagung bestätigen können.
                                            </b-popover>
                                        </template>
                                    </div>
                                </template>
                                <template v-else><!-- WIEDERVERANLAGEN -->
                                    <small class="col-md-12 small-text">
                                        <b>Zeichnungsberechtigte können bis {{data['SAPTerminEL - Laufzeitende'] | formatDateAddDays(-3)}} eine automatische Wiederveranlagung beauftragen.</b>
                                        <info-button v-if="!isDisabled(data)" >
                                            Bitte beachten Sie, dass Sie für den Auftrag einer automatischen Wiederveranlagung
                                            am Konto zeichnungsberechtigt sein müssen.
                                        </info-button>
                                    </small>

                                    <div class="col-md-12 mt-2">
                                        <b-button v-if="!isDisabled(data)" :to="{ name: 'deposit-reinvest', params: {id: data.egoId}}" size="sm" variant="primary" class="w-100">WIEDERVERANLAGEN</b-button>
                                        <template v-else>
                                            <b-button id="WIEDERVERANLAGEN" size="sm" variant="secondary" class="w-100">WIEDERVERANLAGEN</b-button>
                                            <b-popover v-if="isExpired(data)" target="WIEDERVERANLAGEN" triggers="hover" placement="top">
                                                Die Beauftragung der Wiederveranlagung ist online leider nicht mehr möglich. Bitte wenden Sie sich an unser Kundenservice.
                                            </b-popover>
                                            <b-popover v-else target="WIEDERVERANLAGEN" triggers="hover" placement="top">
                                               Bitte beachten Sie, dass Sie für den Auftrag einer automatischen Wiederveranlagung am Konto zeichnungsberechtigt sein müssen.
                                            </b-popover>
                                        </template>
                                    </div>
                                </template>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="modal-description" ok-title="SPEICHERN" cancel-title="abbrechen" @ok="saveDescription">
            <template v-slot:modal-title>
                <b>Kontobezeichnung ändern</b>
            </template>

            <form ref="form" @submit.stop.prevent novalidate v-bind:class="{ 'was-validated': validated }">
                <b-row>
                    <form-page :page="descriptionForm" :data="selected" class="col-12" />
                </b-row>
            </form>
        </b-modal>        
    </page-layout>
</template>

<script>
    import PageLayout from '@/layouts/KontomanagerLayout.vue'
    import BigAmount from '@/components/BigAmount.vue'
    import FormPage from '@/shared/components/forms/FormPage'

    import descriptionForm from '@/config/AccountDescriptionForm.json'
    import productService from '@/services/product'
    import { mapGetters } from 'vuex'
    import moment from 'moment';

    export default {
        name: 'DepositPage',

        components: {
            PageLayout, BigAmount, FormPage
        },

        data() {
            return {
                sort: null,
                list: [],
                selected: {},
                validated: false,
                isLoading: true,
                descriptionForm,
                registrationURL: process.env.VUE_APP_REGISTRATION_URL
            };
        },

        mounted: function() {
            productService.getDeposits().then(data => {
                this.list=data;
                this.isLoading=false;
            }).catch((error) => {
                this.isLoading=false;
                this.showError(error.message)
            })
        },

        computed: {
            ...mapGetters(['user', 'api']), 
            eye4: function() {
                return this.user.foureyesprinciple
            }
        },

        methods: {
            extended(data) {
                return data['SAPTerminEL - Wiederveranlagt - Web'];
            },

            storno(data) {
                return data['SAPTerminEL - storniert CustomerID']!=null;
            },

            reinvest(data) {
                return data['SAPTerminEL - wiederveranlagt CustomerID']!=null;
            },

            editDescription(data) {
                this.selected=data;
                this.selected.newDescription="";
                this.validated = false;

                this.$bvModal.show('modal-description');
            },

            saveDescription(bvModalEvt) {
                if (this.$refs.form.checkValidity() === false) {
                    this.validated = true;
                    bvModalEvt.preventDefault();
                    return;
                }

                this.isLoading=true;

                productService.updateProductDescription(this.selected.egoId, this.selected.newDescription).then(() => {
                    this.selected.description=this.selected.newDescription;
                    this.isLoading=false;
                }).catch((error) => {
                    this.isLoading=false;
                    this.showError(error.message)
                })
            },

            sameUser: function(data) {
                if (this.eye4 && this.user.signatory) {
                    var currentID=null;

                    if (this.extended(data) && this.storno(data)) { // BESTÄTIGEN 
                        currentID=data['SAPTerminEL - storniert CustomerID']
                    }
                    else if (this.reinvest(data)) { // FREIGEBEN
                        currentID=data['SAPTerminEL - wiederveranlagt CustomerID'];
                    }

                    if (currentID!=null) {
                        var userID=this.user.userCustomerId!=null ? this.user.userCustomerId : this.user.customerId;
                        if (currentID==userID) {
                            return true; // For 4 eyes differnt users must confirm action
                        }
                    }
                } 

                return false;
            },

            isDisabled: function(data) {
                if (this.sameUser(data)) {
                    return true; // For 4 eyes differnt users must confirm action
                }

                if (this.isExpired(data)) {
                    return true
                }

                return !this.user.signatory
            },

            isExpired: function (data) {
                var date=moment(data['SAPTerminEL - Laufzeitende']).add(-2, 'days');
                return moment().isAfter(date)
            },

            info() {
                this.$store.dispatch('setInfo', {title: "Festgeld erfolgreich wiederveranlagt!", text: "Vielen Dank für Ihren Auftrag! Die Auftragsbestätigung wird in Kürze an Ihre E-Mail-Adresse geschickt."})
                this.$router.push({ name: 'info' })
            }

        }
    }
</script>

<style lang="scss">
</style>