/* Method for getting and updating list data */

import axios from 'axios'
import errorParser from '@/shared/utils/ErrorParser'

export default {

    /**
     * Upload files on the server
     * @param {object} formData with uuid and file list
     */
    upload(formData) {

        return new Promise((resolve, reject) => {
            axios.post('/file/upload', formData).then(({ data }) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Delete file on the server
     * @param {long} id file id to delete
     */
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.get('/file/delete/'+id).then(({ data }) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Delete aal files from upload area on the server
     * @param {string} uuid
     * @param {string} area
     */
    deleteAll(uuid, area) {
        return new Promise((resolve, reject) => {
            axios.get('/file/delete-all/'+encodeURI(area)+"/"+uuid).then(({ data }) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    }

}
