/* Common methods for Vue components */

import moment from 'moment';
import i18n from '@/shared/i18n'

export default {
    methods: {

        /**
         * Shows error message
         * @param {String} message 
         */
        showError(message) {
            if (message.startsWith("error.")) {
                message=i18n.t(message)
            }

            console.log(message);
            this.$bvToast.toast(message, {
                title: 'Error',
                autoHideDelay: 10000,
                variant: 'danger',
                appendToast: true
            });
        },
        
        /**
         * Shows info message
         * @param {String} message 
         */
        showInfo(message) {
            if (message.startsWith("message.")) {
                message=i18n.t(message)
            }

            this.$bvToast.toast(message, {
                title: 'Information',
                autoHideDelay: 5000,
                variant: 'success',
                appendToast: true
            });
        },

        /**
         * Show confirm dialog and returns Promise
         * @param {*} title 
         * @param {*} message 
         */
        confirm(title, message, okTitle='YES', cancelTitle='NO') {
            return new Promise((resolve) => {
                this.$bvModal.msgBoxConfirm(message, {title, okVariant: 'primary', okTitle, cancelTitle}).then(result => {
                    if (result) {
                        resolve()   
                    }
                });
            });
        },

        /**
         * Remove illegal chars in component ID
         * @param {String} id 
         */
        toID(id) {
            return id.replace(/[-[\]\s]/g, '').replace(/\./g, '_');
        },

        formatDateTime(value, format='DD.MM.YYYY HH:mm:ss') {
            if (value) {
                return moment(String(value)).format(format);
            }
        },

        dateTimeFormatter(value) {
            if (value) {
                return moment(String(value)).format('DD.MM.YYYY HH:mm:ss');
            }
        },

        parseDateTime(value, format='DD.MM.YYYY HH:mm:ss') {
            if (value) {
                return moment(value, format).utc().format();
            }
        },

        formatNumber(amount, decimal=2, useGrouping=true) {
            if (!amount) {
                amount=0;
            }
            
            return new Intl.NumberFormat('de-DE', {minimumFractionDigits: decimal, maximumFractionDigits: decimal, useGrouping}).format(amount);
        },

        parseNumber(stringNumber) {
            if (stringNumber) {
                var thousandSeparator = '.';
                var decimalSeparator = ',';

                stringNumber=stringNumber.replace(new RegExp('\\' + thousandSeparator, 'g'), '').replace(new RegExp('\\' + decimalSeparator), '.')

                return stringNumber.match(/^[0-9\\.]+$/) ? parseFloat(stringNumber) : NaN;
            }

            return null
        },

        /**
         * Returm current time as string
         */
        now() {
            return moment().utc().format();
        },

        /**
         * Returm current date as string
         */
        today() {
            return moment().format('YYYY-MM-DD'); 
        },

        /**
         * Prints current web page
         */
        printPage() {
            window.print();
        },

        setCustomValidity(id, message) {
            var element=document.getElementById(this.toID(id));
            if (element!=null) {
                element.setCustomValidity(message);
            }
        },

        /**
         * Returns "off" for Firefox and "no" for other browsers
         */
        noAutocomplete() {
            return navigator.userAgent.search("Firefox")>=0 ? "off" : "no";
        }

    }
}
