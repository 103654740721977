import Vue from 'vue'
import Router from 'vue-router'

import LoginPage from '@/views/LoginPage.vue'

import OverviewPage from '@/views/OverviewPage'
import FinancePage from '@/views/FinancePage'
import DepositPage from '@/views/DepositPage'

import PageNotFound from '@/views/PageNotFound'
import ErrorPage from '@/views/ErrorPage'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/', redirect: '/login' },
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
            meta: { requiresAuth: false }
        },
        {
            path: '/reminder',
            name: 'reminder',
            component: () => import('./views/ReminderPage.vue'),
            meta: { requiresAuth: false }
        },
        {
            path: '/overview',
            name: 'overview',
            component: OverviewPage,
            meta: { requiresAuth: true }
        },
        {
            path: '/finance',
            name: 'finance',
            component: FinancePage,
            meta: { requiresAuth: true }
        },
        {
            path: '/finance/documents/:id',
            name: 'finance-documents',
            component: () => import('./views/FinanceDocumentsPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit',
            name: 'deposit',
            component: DepositPage,
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit/new',
            name: 'deposit-new',
            component: () => import('./views/DepositNewPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit/reinvest/:id',
            name: 'deposit-reinvest',
            component: () => import('./views/DepositReinvestPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit/release/:id',
            name: 'deposit-release',
            component: () => import('./views/DepositReleasePage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit/storno/:id',
            name: 'deposit-storno',
            component: () => import('./views/DepositStornoPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit/storno4/:id',
            name: 'deposit-storno-4',
            component: () => import('./views/DepositStorno4Page.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit/payment/:amount/:id',
            name: 'deposit-payment',
            component: () => import('./views/DepositPaymentPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/docs',
            name: 'documents',
            component: () => import('./views/DocumentsPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('./views/UserPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/user/list',
            name: 'users',
            component: () => import('./views/UsersPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/user/new',
            name: 'user-new',
            component: () => import('./views/UserNewPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/request',
            name: 'request',
            component: () => import('./views/RequestPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/deposit/request',
            name: 'deposit-request',
            component: () => import('./views/DepositRequestPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/password/new',
            name: 'password-new',
            component: () => import('./views/PasswordNewPage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/password',
            name: 'password',
            component: () => import('./views/PasswordChangePage.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import('./views/LogoutPage.vue'),
            meta: { requiresAuth: false }
        },
        {
            path: '/info',
            name: 'info',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('./views/InfoPage.vue'),
            meta: { requiresAuth: false }
        },
        {
            path: "/errors", 
            name: 'errors',
            component: ErrorPage,
            meta: { requiresAuth: false }
        },
        {
            path: "*", 
            name: 'not-found',
            component: PageNotFound,
            meta: { requiresAuth: false }
        }
    ]
})
