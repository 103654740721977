<template>

    <b-form-group :label-for="toID(field.name)" :label-class="labelClass" >
        <template v-if="field.label" slot="label">
            <span v-html="field.label" />
            <mandatory-flag v-if="mandatory" />
            <info-button v-if="field.info"><div v-html="field.info" /></info-button>
        </template>

        <template slot="description">{{field.description}}</template>

        <div class="input-group">
            <div v-if="field.prefix" class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">{{field.prefix}}</span>
            </div>

            <b-form-input ref="input" :id="toID(field.name)" :name="toID(field.name)" v-model="value" :class="{'text-right': field.align=='R', 'text-center': field.align=='C', 'rounded-right': !field.unit}" @focus="focus" @blur="blur" type="text" :required="mandatory" :readonly="readonly" :placeholder="field.placeholder" :maxlength="field.maxLength" @keydown.enter.prevent="$emit('enter')" />

            <div v-if="field.unit" class="input-group-append">
                <span class="input-group-text rounded-right" id="basic-addon2">{{field.unit}}</span>
            </div>

            <b-form-invalid-feedback >
                <div v-if="field.mandatoryMessage && !(value)" v-html="field.mandatoryMessage" />
                <div v-else-if="field.formatMessage && formatError" v-html="field.formatMessage" />
                <div v-else-if="field.validationMessage && customError" v-html="field.validationMessage" />
            </b-form-invalid-feedback>
        </div>
    </b-form-group>

</template>

<script>
    import Field from '@/shared/utils/Field'
    import Calculation from '@/shared/utils/Calculation'
    import EvaluateCondition from '@/shared/utils/EvaluateCondition'

    export default {
        name: 'FormNumber',

        props: {
            field: Object,
            data: Object,
            labelClass: String
        },

        data() {
            return {
                customError: false, 
                valid: false,
                localValue: "",
                formatError: false,
                isInputActive: false
            };
        },

        computed: {
            value: {
                get: function () {
                    if (this.field.formula!=null) {
                        return this.calculate(); // Calculated field
                    }

                    if (!this.isInputActive && this.valid) {
                        return this.getValue();
                    }

                    return this.localValue;
                },

                set: function (newValue) {
                    this.localValue=newValue;
                    this.setValue(newValue);
                }
            },

            decimal: function () {
                var format=this.field.format;

                if (format) {
                    var n=format.indexOf('.');
                    return n<0 ? 0 : format.length-n-1;
                }

                return 2;
            }, 
            
            useGrouping: function () {
                var format=this.field.format;

                if (format) {
                    return format.indexOf(',')>=0;
                }
                
                return true;
            },

            readonly: function () {
                if (this.field.readonly) {
                    return true;
                } 

                if (this.field.readonlyIf) {
                    return EvaluateCondition.evaluate(this.field.readonlyIf, this.data);
                }

                return false;
            },

            mandatory: function () {
                if (this.field.mandatory) {
                    return true;
                } 

                if (this.field.mandatoryIf) {
                    return EvaluateCondition.evaluate(this.field.mandatoryIf, this.data);
                }

                return false;
            }

        },

        mounted: function () {
            this.value=this.getValue();

            if (this.field.defaultValue) {
                this.setValue(this.value); // Store default value to the data
            }
        },
        
        methods: {

            getValue() {
                var value=Field.getValue(this.field.name, this.data, this.field.defaultValue);
                if (value!=null) {
                    value=this.formatNumber(value, this.decimal, this.useGrouping);
                }

                return value;
            },

            setValue(newValue) {
                if (newValue=="" || newValue==null) {
                    Field.clearField(this.field.name, this.data, this.$delete);
                }
                else {
                    var number = this.parseNumber(newValue);
                    if (isNaN(number)) {
                        document.getElementById(this.toID(this.field.name)).setCustomValidity(this.field.validationMessage);
                        this.formatError=true;
                        Field.clearField(this.field.name, this.data, this.$delete);
                        this.valid=false;
                        return false;
                    }
                    this.formatError=false;

                    Field.setValue(this.field.name, number, this.data, this.$set)
                }

                this.valid=this.validate();
                return this.valid;
            },

            validate() {
                if (!this.formatError && this.field.validIf) {
                    var validity=this.$refs.input.validity;
                    //console.log(validity);
                    
                    if (!validity.typeMismatch && !validity.patternMismatch && !validity.valueMissing) {
                        var valid=EvaluateCondition.evaluate(this.field.validIf, this.data);
                        if (!valid) {
                            document.getElementById(this.toID(this.field.name)).setCustomValidity(this.field.validationMessage);
                            this.customError=true;
                            return false;
                        }
                    }
                }

                document.getElementById(this.toID(this.field.name)).setCustomValidity("");
                this.customError=false;
                return true;
            }, 

            calculate() {
                var value=Calculation.evaluate(this.field.formula, this.data);

                if (!Number.isNaN(value)) {
                    Field.setValue(this.field.name, value, this.data, this.$set)
                    return this.getValue();
                }

                Field.clearField(this.field.name, this.data, this.$delete);
                return null;
            },

            focus() {
                if (!this.formatError) {
                    this.localValue=this.getValue();
                }

                this.isInputActive = true;
            },

            blur() {
                // Store value
                if (this.value && this.setValue(this.value)) {
                    this.value=this.getValue();
                }

                this.isInputActive = false;
            }

        }
    }
</script>

<style lang="scss">
</style>
