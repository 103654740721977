<template>

    <b-form-group :label-for="toID(field.name)" :label-class="labelClass" >
        <template v-if="field.label" slot="label">
            <span v-html="field.label" />
            <mandatory-flag v-if="mandatory" />
            <info-button v-if="field.info"><div v-html="field.info" /></info-button>
        </template>

        <template slot="description">{{field.description}}</template>

        <b-form-input ref="input" :id="toID(field.name)" v-model="value" @blur="blur" type="text" :required="mandatory" :readonly="readonly" :placeholder="field.placeholder" @keydown.enter.prevent />
        <b-form-invalid-feedback >
            <div v-if="field.mandatoryMessage && !(value)" v-html="field.mandatoryMessage" />
            <div v-else-if="field.formatMessage && !customError" v-html="field.formatMessage" />
            <div v-else-if="field.validationMessage && customError" v-html="field.validationMessage" />
        </b-form-invalid-feedback>
    </b-form-group>

</template>

<script>
    import Field from '@/shared/utils/Field'
    import EvaluateCondition from '@/shared/utils/EvaluateCondition'

    export default {
        name: 'FormInput',

        props: {
            field: Object,
            data: Object,
            labelClass: String
        },

        data() {
            return {
                customError: false
            };
        },

        computed: {
            value: {
                get: function () {
                    return EvaluateCondition.getCondition(Field.getValue(this.field.name, this.data, this.field.defaultValue))
                } /* TODO,

                set: function (newValue) {
                    if (newValue=="" || newValue==null) {
                        Field.clearField(this.field.name, this.data, this.$delete);
                    }
                    else {
                        Field.setValue(this.field.name, newValue, this.data, this.$set)
                    }

                    this.validate();
                }*/
            },

            readonly: function () {
                if (this.field.readonly) {
                    return true;
                } 

                if (this.field.readonlyIf) {
                    return EvaluateCondition.evaluate(this.field.readonlyIf, this.data);
                }

                return false;
            },

            mandatory: function () {
                if (this.field.mandatory) {
                    return true;
                } 

                if (this.field.mandatoryIf) {
                    return EvaluateCondition.evaluate(this.field.mandatoryIf, this.data);
                }

                return false;
            }

        },

        mounted: function () {
        },
        
        methods: {
            validate() {
                if (this.field.validIf) {
                    var validity=this.$refs.input.validity;

                    //console.log(validity);
                    if (!validity.typeMismatch && !validity.patternMismatch) {
                        var valid=EvaluateCondition.evaluate(this.field.validIf, this.data);
                        if (!valid) {
                            document.getElementById(this.toID(this.field.name)).setCustomValidity(this.field.validationMessage);
                            this.customError=true;
                            return false;
                        }
                    }
                }

                document.getElementById(this.toID(this.field.name)).setCustomValidity("");
                this.customError=false;
                return true;
            }, 

            blur() {
            }

        }
    }
</script>

<style lang="scss">
</style>
