
<script>
    import Vue from 'vue';

    export default {
        name: 'VueRender',

        props: ['code', 'data', 'uuid', 'api'],

        data() {
            return {
                templateRender: null
            };
        },

        render(h) {
            if (!this.templateRender) {
                return h('div', '');
            } else { // If there is a template, I'll show it
                return this.templateRender();
            }
        },

        watch: {
            // Every time the template prop changes, I recompile it to update the DOM
            template: {
                immediate: true, // makes the watcher fire on first render, too.
                handler() {
                    var res = Vue.compile("<div>" + this.code + "</div>");

                    this.templateRender = res.render;

                    // staticRenderFns belong into $options, 
                    // appearantly
                    this.$options.staticRenderFns = []

                    // clean the cache of static elements
                    // this is a cache with the results from the staticRenderFns
                    this._staticTrees = []

                    // Fill it with the new staticRenderFns
                    for (var i in res.staticRenderFns) {
                        //staticRenderFns.push(res.staticRenderFns[i]);
                        this.$options.staticRenderFns.push(res.staticRenderFns[i])
                    }
                }
            }
        }
        //render: res.render,
        //staticRenderFns: res.staticRenderFns,
        /*render(h) {
            return h({
              template: "<div>"+this.code+"</div>",
            })
          }*/
        /*render(h) {
            var compiledTemplate = Vue.compile("<div>" + this.code + "</div>");
            //return h(Vue.compile("<div>"+this.code+"</div>", { data: this.data }) ) // compile and render 'code' string
            //return h(compiledTemplate, {msg: this.msg});
            return h(compiledTemplate);
            //return compiledTemplate.render.call(this, h);
            //return compiledTemplate.render();
        }*/
        /*mounted() {
            var compiledTemplate = Vue.compile("<div>"+this.code+"</div>");
        
            console.log(compiledTemplate)
        
            this.templateRender = compiledTemplate.render;
        }*/
    }
</script>

