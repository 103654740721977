<template>
    <span class="mandatory">*</span>
</template>

<script>
    export default {
        name: 'MandatoryFlag',
    }
</script>

<style lang="scss">
    span.mandatory {
        color: red;
    }
</style>
