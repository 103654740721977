<template>
    <div>
        <b-form-radio :id="toID(field.name+'.'+field.value)" :name="field.name" v-model="value" :value="field.value" :required="mandatory" :disabled="readonly" @keydown.native.enter.prevent >
            <span v-if="field.label" v-html="field.label" />
            <mandatory-flag v-if="mandatory" />
            <info-button v-if="field.info"><div v-html="field.info" /></info-button>
        </b-form-radio>

        <b-form-invalid-feedback class="custom-invalid-feedback">
            <div v-if="field.mandatoryMessage && !(value)" v-html="field.mandatoryMessage" />
            <div v-else-if="field.validationMessage && customError && value" v-html="field.validationMessage" />
        </b-form-invalid-feedback>
    </div>

</template>

<script>
    import Field from '@/shared/utils/Field'
    import EvaluateCondition from '@/shared/utils/EvaluateCondition'

    export default {
        name: 'FormRadio',

        props: {
            field: Object,
            data: Object
        },

        computed: {
            value: {
                get: function () {
                    return Field.getValue(this.field.name, this.data, this.field.defaultValue)
                },

                set: function (newValue) {
                    Field.setValue(this.field.name, newValue, this.data, this.$set);
                    this.isCustomError();
                }
            },

            customError() {
                return this.isCustomError();
            },

            readonly: function () {
                if (this.field.readonly) {
                    return true;
                } 

                if (this.field.readonlyIf) {
                    return EvaluateCondition.evaluate(this.field.readonlyIf, this.data);
                }

                return false;
            },

            mandatory: function () {
                if (this.field.mandatory) {
                    return true;
                } 

                if (this.field.mandatoryIf) {
                    return EvaluateCondition.evaluate(this.field.mandatoryIf, this.data);
                }

                return false;
            }

        },

        mounted: function () {
            if (this.field.defaultValue) {
                this.value=this.field.defaultValue; // Store default value to the data
            }
        },

        methods: {
            isCustomError() {
                var input=document.getElementById(this.toID(this.field.name+'.'+this.field.value));

                if (this.field.validIf) {
                    var valid=EvaluateCondition.evaluate(this.field.validIf, this.data);
                    if (!valid) {
                        if (input) {
                            input.setCustomValidity(this.field.validationMessage);
                        }
                        return true;
                    }
                }

                if (input) {
                    input.setCustomValidity("");
                }

                return false;
            }
        }
        
    }
</script>

<style lang="scss">
</style>
