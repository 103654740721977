<template>
    <div class="big-amount">
        <span class="big-amount-amount">{{main}}</span>,<span class="big-amount-small">{{decimal}} EUR</span>
    </div>
</template>

<script>
    export default {
        name: 'BigAmount',

        props: {
            amount: Number
        },

        computed: {
            main: function() {
                return this.formatNumber(Math.trunc(this.amount), 0, true);
            },

            decimal: function() {
                var decimal=Math.floor(Math.abs((this.amount-Math.trunc(this.amount)).toFixed(2)*100));
                return decimal<10 ? "0"+decimal : decimal;
            }
        },
    }
</script>

<style lang="scss">
    .big-amount-amount {
        font-weight: bold;
        font-size: 1.9rem;
    }

    .big-amount-small {
        font-size: 0.8rem;
    }
</style>
