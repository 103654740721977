/* Methods for getting and setting value for field in multilevel data object */

import uploadService from '@/services/upload'

export default {

    getValue(fieldName, data, defaultValue=null) {
        var names = fieldName.split('.');
        var value = data;

        for (var i = 0; i < names.length; i++) {
            var name=names[i];
            var index=-1;

            if (name.endsWith("]")) {
                var p=name.indexOf("[");
                index= parseInt(name.substr(p+1, name.length-p-2));
                name=name.substr(0, p);
            }

            value = value[name];
            if (typeof (value) == "undefined") {
                return defaultValue;
            }

            if (index>=0) {
                value=value[index];

                if (typeof (value) == "undefined") {
                    return defaultValue;
                }
            }
        }

        return value;
    },

    setValue(fieldName, newValue, data, $set) {
        var names = fieldName.split('.');
        var variable = data;

        //console.log("setValue "+fieldName+" to "+newValue)

        for (var i = 0; i < names.length - 1; i++) {
            var name=names[i];
            var index=-1;

            if (name.endsWith("]")) {
                var p=name.indexOf("[");
                index= parseInt(name.substr(p+1, name.length-p-2));
                name=name.substr(0, p);
            }

            if (typeof (variable[name]) == "undefined") {
                if (typeof($set) === "function") {
                    $set(variable, name, index<0 ? {} : []);
                }
                else {
                    variable[name]=index<0 ? {} : []
                }
            }

            variable = variable[name];

            if (index>=0 && typeof (variable) != "undefined") {
                for (var x=variable.length; x<=index; x++) {
                    variable.push({})
                }

                variable=variable[index];
            }
        }

        if (typeof($set) === "function") {
            $set(variable, names[names.length - 1], newValue);
        }
        else {
            variable[names[names.length - 1]]=newValue;
        }
    },

    /**
     * Remove field from parent object
     * @param {string} fieldName 
     * @param {object} data 
     * @param {function} $delete function
     */
    clearField(fieldName, data, $delete) {
        var names = fieldName.split('.');
        var variable = data;

        //console.log("clearField "+fieldName)

        for (var i = 0; i < names.length - 1; i++) {
            var name=names[i];
            var index=-1;

            if (name.endsWith("]")) {
                var p=name.indexOf("[");
                index= parseInt(name.substr(p+1, name.length-p-2));
                name=name.substr(0, p);
            }
            
            if (typeof (variable[name]) == "undefined") {
                return; // Parent doesn't exist -> no need to clear field
            }

            variable = variable[name];

            if (index>=0 && typeof (variable) != "undefined") {
                if (index>=variable.length) {
                    return; // Array items doesn't exist -> no need to clear field
                }

                variable=variable[index];
            }
        }

        if (typeof($delete) === "function") {
            if (typeof (variable) == "object") {
                $delete(variable, names[names.length - 1]);
            }
        }
        else {
            delete variable[names[names.length - 1]];
        }
    },

    /**
     * Remove fields from parent objects
     * @param {array} fieldNames 
     * @param {object} data 
     * @param {function} $delete function
     */
    clearFields(fieldNames, data, $delete) {
        for (var fieldName of fieldNames) {
            this.clearField(fieldName, data, $delete);
        }
    },

    /**
     * Gets all field names used in this row
     * @param {Row} row 
     * @param {Map} fields 
     */
    getRowFieldNames(row, fields) {
        var result=[];

        for (var col of row.cols) {
            if (col.fieldName) {
                var field=fields[col.fieldName];
                result.push(field.name)
            }
        }

        return result;
    },

    /**
     * Gets all upload field names used in this row
     * @param {Row} row 
     * @param {Map} fields 
     */
    getRowUploadFieldNames(row, fields) {
        var result=[];

        for (var col of row.cols) {
            if (col.fieldName) {
                var field=fields[col.fieldName];
                if (field.type=='U') {
                    result.push(field.name)
                }                
            }
        }

        return result;
    },

    /**
     * Gets all field names used in this group
     * @param {Group} group 
     */
    getGroupFieldNames(group) {
        var result=[];

        for (var field in group.fields) {
            result.push(group.fields[field].name)
        }

        return result;
    },

    /**
     * Gets all upload field names used in this group
     * @param {Group} group 
     */
    getGroupUploadFieldNames(group) {
        var result=[];

        for (var field in group.fields) {
            if (field.type=='U') {
                result.push(group.fields[field].name)
            }
        }

        return result;
    },

    /**
     * Gets all field names used in this page
     * @param {Page} page 
     */
    getPageFieldNames(page) {
        var result=[];

        for (var group of page.groups) {
            for (var field in group.fields) {
                result.push(group.fields[field].name)
            }
        }

        return result;
    },

    /**
     * Gets all upload field names used in this page
     * @param {Page} page 
     */
    getPageUploadFieldNames(page) {
        var result=[];

        for (var group of page.groups) {
            for (var field in group.fields) {
                if (field.type=='U') {
                    result.push(group.fields[field].name)
                }
            }
        }

        return result;
    },

    /**
     * Remove files for upload field
     * @param {string} fieldName 
     * @param {string} uuid 
     * @param {object} attachments 
     * @param {function} $delete function
     */
    deleteUpload(fieldName, uuid, attachments, $delete) {
        if (attachments[fieldName]) {
            console.log("Delete files for "+fieldName)
            uploadService.deleteAll(uuid, fieldName).then( () => {
                $delete(attachments, fieldName)
            })
        }
    },

    /**
     * Remove files for upload fields
     * @param {array} fieldNames 
     * @param {string} uuid 
     * @param {object} attachments 
     * @param {function} $delete function
     */
    deleteUploads(fieldNames, uuid, attachments, $delete) {
        for (var fieldName of fieldNames) {
            this.deleteUpload(fieldName, uuid, attachments, $delete);
        }
    }

}
