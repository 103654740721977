<template>

    <b-form-group :label-for="toID(field.name)" :label-class="labelClass" >
        <template v-if="field.label" slot="label">
            <span v-html="field.label" />
            <mandatory-flag v-if="mandatory" />
            <info-button v-if="field.info"><div v-html="field.info" /></info-button>
        </template>

        <template slot="description">{{field.description}}</template>

        <div class="input-group">
            <div v-if="field.prefix" class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">{{field.prefix}}</span>
            </div>

            <b-form-input ref="input" :id="toID(field.name)" :name="toID(field.name)" v-model="value" :class="{'text-right': field.align=='R', 'text-center': field.align=='C', 'rounded-right': !field.unit}" 
                @blur="blur" :type="fieldType" :required="mandatory" :readonly="readonly" :title="field.title" :pattern="pattern" :placeholder="field.placeholder" 
                :maxlength="field.maxLength" @paste="onPaste" @keydown.enter.prevent="$emit('enter')" :autocomplete="autocomplete" />

            <div v-if="field.unit" class="input-group-append">
                <span class="input-group-text rounded-right">{{field.unit}}</span>
            </div>

            <b-form-invalid-feedback>
                <div v-if="field.mandatoryMessage && !(value)" v-html="field.mandatoryMessage" />
                <div v-else-if="field.formatMessage && htmlError" v-html="field.formatMessage" />
                <div v-else-if="field.validationMessage && customError" v-html="field.validationMessage" />
            </b-form-invalid-feedback>
        </div>
        
    </b-form-group>

</template>

<script>
    import Field from '@/shared/utils/Field'
    import EvaluateCondition from '@/shared/utils/EvaluateCondition'

    export default {
        name: 'FormInput',

        props: {
            field: Object,
            data: Object,
            labelClass: String
        },

        data() {
            return {
                htmlError: false,
            };
        },

        computed: {
            value: {
                get: function () {
                    return Field.getValue(this.field.name, this.data, this.field.defaultValue)
                },

                set: function (newValue) {
                    if (newValue=="" || newValue==null) {
                        Field.clearField(this.field.name, this.data, this.$delete);
                    }
                    else {
                        Field.setValue(this.field.name, newValue, this.data, this.$set)
                    }

                    this.isCustomError();
                }
            },

            customError() {
                return this.isCustomError();
            } ,

            fieldType() {
                switch (this.field.type) {
                    case "E": return "text";
                    case "H": return "hidden";
                    case "W": return "password";
                }

                return "text";
            },

            pattern() {
                if (this.field.format) {
                    return this.field.format;
                }
                
                if (this.field.type=='E') { 
                    return "[^@\\s]+@[^@\\s]+\\.[^@\\s]+"; // To handle umplaute in emails
                }

                return null;
            },

            readonly: function () {
                if (this.field.readonly) {
                    return true;
                } 

                if (this.field.readonlyIf) {
                    return EvaluateCondition.evaluate(this.field.readonlyIf, this.data);
                }

                return false;
            },

            mandatory: function () {
                if (this.field.mandatory) {
                    return true;
                } 

                if (this.field.mandatoryIf) {
                    var result=EvaluateCondition.evaluate(this.field.mandatoryIf, this.data);
                    //console.log(this.field.name+" mandatory "+result+" "+JSON.stringify((this.field.mandatoryIf)));
                    return result;
                }

                return false;
            },

            autocomplete: function () {
                if (this.field.type=="W") { // password fields
                    return this.field.autocomplete===undefined ? null : (this.field.autocomplete ? null : "new-password");
                }

                return this.field.autocomplete===undefined ? null : (this.field.autocomplete ? null : this.noAutocomplete());
            }

        },

        mounted: function () {
            if (this.field.defaultValue) {
                this.value=this.field.defaultValue; // Store default value to the data
            }
        },
        
        methods: {
            isCustomError() {
                var input=document.getElementById(this.toID(this.field.name));
                this.htmlError=input ? input.validity.typeMismatch || input.validity.patternMismatch : false;

                if (this.field.validIf) {
                    //console.log(validity);
                    var valid=EvaluateCondition.evaluate(this.field.validIf, this.data);
                    if (!valid) {
                        if (input) {
                            input.setCustomValidity(this.field.validationMessage);
                        }
                        return true;
                    }
                }

                if (input) {
                    input.setCustomValidity("");
                }

                return false;
            }, 

            blur() {
                // Trim value on focus lost
                if (this.value) {
                    this.value=this.value.trim();
                }                
            },

            onPaste(evt) {
                if (this.field.pasteDisabled) {
                    evt.preventDefault();
                }                
            }

        }
    }
</script>

<style lang="scss">
</style>
