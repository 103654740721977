import _ from 'lodash'
import i18n from '@/shared/i18n'

export default {
    parse(error) {
        console.log(error);
        
        if (error.response) {
            const status = error.response.status
            const data = error.response.data

            if (data && data.message) {
                return new Error(data.message.startsWith("error.") ? i18n.t(data.message) : data.message)
            }
            if (status === 400) {
                return new Error(i18n.t('error.request.bad'))
            } else if (status === 401) {
                return new Error(i18n.t('error.request.notAuthorized'))
            } else if (status === 403) {
                return new Error(i18n.t('error.request.forbidden'))
            } else if (status === 404) {
                return new Error(i18n.t('error.request.notFound'))
            } else if (status === 500) {
                return new Error(i18n.t('error.request.unknownServerError'))
            } else {
                return new Error(i18n.t('error.request.failed'))
            }
        } else if (error.request) {
            // Request was made and no response
            return new Error(i18n.t('error.request.noResponse'))
        } else {
            return _.isError(error) ? error : new Error(error)
        }
    }
}
