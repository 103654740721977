import Field from '@/shared/utils/Field'
import moment from 'moment';

export default {

    evaluate(condition, data) {

        //console.log("Evaluating "+JSON.stringify(condition));

        if (condition.fieldName) {
            var value=Field.getValue(condition.fieldName, data)

            //console.log(condition.fieldName+"="+value);

            var result=this.compare(data, condition.operator, value, condition.fieldValue, condition.secondValue, condition.add, condition.unit);

            //console.log("Result "+result+" "+(condition.not ? !result : result));

            return condition.not ? !result : result;
        }
        else {
            if (condition.and) {
                for (var andCondition of condition.and) {
                    if(!this.evaluate(andCondition, data)) {
                        return condition.not ? true : false;
                    }
                }

                return condition.not ? false : true
            }

            if (condition.or) {
                for (var orCondition of condition.or) {
                    if(this.evaluate(orCondition, data)) {
                        return condition.not ? false : true;
                    }
                }

                return condition.not ? true : false
            }

            if (condition.count) {
                var count=0;
                for (var countCondition of condition.count) {
                    var name=countCondition.fieldName;

                    if (name && name.includes("[]")) {
                        var n = name.indexOf("[]");
                        var arrayName=name.substr(0, n);
                        var array=Field.getValue(arrayName, data)
                        
                        if (array) {
                            for (var index=0; index<array.length; index++) {
                                countCondition.fieldName = name.replace(/\[\]/g, "["+index+"]");
                                if(this.evaluate(countCondition, data)) {
                                    count++;
                                }
                            }

                            countCondition.fieldName=name; // reset to old value
                        }                       
                    }
                    else {
                        if(this.evaluate(countCondition, data)) {
                            count++;
                        }
                    }
                }

                result=this.compare(data, condition.operator, count, condition.fieldValue, condition.secondValue, condition.add, condition.unit);

                return condition.not ? !result : result
            }

            console.log("Wrong condition: "+JSON.stringify(condition))
            return false;
        }

    },

    compare(data, operator, value, other, second, add=0, unit="") {
        if (other && other.toString().startsWith("=")) {
            other=this.evaluateFormula(data, other.substr(1))
        }

        if (second && second.toString().startsWith("=")) {
            second=this.evaluateFormula(data, second.substr(1))
        }
        
        if (value==="") {
            value=null;
        }

        if (other==="") {
            other=null;
        }
        else if (add!=0) {
            if (unit) { // date manipulation
                var date = moment(other).add(add, unit);
                other=other.length>10 ? date.utc().format() : date.format("YYYY-MM-DD");
            }
            else { // add number
                other=other+add;
            }
        }

        if (second==="") {
            second=null;
        }

        var result=false;

        switch (operator)  {
            case "!=" : result=value!=other; break;
            case "<" : result=value<other; break;
            case "<=" : result=value<=other; break;
            case ">" : result=value>other; break;
            case ">=" : result=value>=other; break;
            case "between" : result=other<=value && value<=second; break;
            case "exists" : result=value!=null; break;
            default : result=value==other; break;
        }

        //console.log(value+operator+other+"="+result)

        return result;
    },

    evaluateFormula(data, formula) {
        if (formula=='date') { // current date witout time
            return moment().format("YYYY-MM-DD");
        }
        else if (formula=='time') { // current time
            return moment().utc().format();
        }
        else { // value from another field
            return Field.getValue(formula, data)
        }
    },
    
    getCondition(condition, prefix="", sufix="") {
        if (!condition) {
            return "";
        }

        let result="";
        if (condition.fieldName!==undefined && condition.fieldValue!==undefined) {
            result="("+prefix+condition.fieldName+sufix+condition.operator+"\""+condition.fieldValue+"\")";
        }
        
        if (condition.or!==undefined && condition.or.length>0) {
            for (var orCondition of condition.or) {
                if (result==="") {
                    result=this.getCondition(orCondition, prefix, sufix);
                }
                else {
                    result=result+" || "+this.getCondition(orCondition, prefix, sufix);
                }
            }
    
            result="("+result+")";
        }
        
        if (condition.and!==undefined && condition.and.length>0) {
            for (var andCondition of condition.and) {
                if (result==="") {
                    result=this.getCondition(andCondition, prefix, sufix);
                }
                else {
                    result=result+" && "+this.getCondition(andCondition, prefix, sufix);
                }
            }
    
            result="("+result+")";
        }
        
        if (condition.not) {
            result="!"+result;
        }
    
        return result;
    }

}
