import axios from 'axios'
import errorParser from '@/shared/utils/ErrorParser'

export default {
        
    /**
     * Authenticate a login request
     * @param {Object} detail login detail
     */
    authenticate(detail) {
        return new Promise((resolve, reject) => {
            axios.post('/authentication', detail).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    setCSRFToken() {
        return new Promise((resolve, reject) => {
            axios.get('/token').then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    }
    
}
