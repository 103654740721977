import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'

Vue.config.productionTip = false;

// Bootstrap axios
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.headers.common.Accept = 'application/json';

axios.interceptors.response.use(
    function (response) {
        if (!axios.defaults.headers.common['X-CSRF-TOKEN']) {
            const CSRF_TOKEN = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`));
            axios.defaults.headers.common['X-CSRF-TOKEN'] = CSRF_TOKEN[1];
        }

        store.commit('expiration');
        return response;
    },

    function (error) {
        return Promise.reject(error);
    }
);

/* Bootstrap */
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

import CommonMixin from '@/shared/mixins/CommonMixin'
Vue.mixin(CommonMixin);

// Check if user is logged or no
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.state.user.authenticated) {
            store.dispatch('getUserData').then(() => {
                next()
            }).catch(() => {
                console.log("Page " + to.name + " redirected to login.")
                next({
                    path: '/login',
                    query: { redirect: to.name }
                })
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

// Global components
import InfoButton from '@/shared/components/forms/InfoButton.vue'
import MandatoryFlag from '@/shared/components/forms/MandatoryFlag.vue'
import VueRender from '@/shared/components/VueRender.vue'

Vue.component('info-button', InfoButton)
Vue.component('mandatory-flag', MandatoryFlag)
Vue.component('vue-render', VueRender)

// Filters
import filters from '@/shared/Filters';

for(let name in filters) {
    Vue.filter(name, filters[name]);
}

new Vue({
  router, 
  store,
  render: h => h(App)
}).$mount('#app')
