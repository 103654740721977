import moment from 'moment';

export default {
        
    updateUserData(state, user) {
        state.user = user;
        state.user.authenticated = true;
    },
    
    logout(state) {
        state.user.name = '';
        state.user.authenticated = false;
    },

    info(state, info) {
        state.info=info;
    },

    expiration(state) {
        const minutes=30;
        var date = moment().add(minutes, 'minutes');
        state.expiration = date / 1000;
    }
    
}
