export default {

    /**
     * Evaluate formula and returns result of it.
     * @param {*} formula to calculate. Data fields should be prefixed with "data."
     * @param {*} data JSON data
     */
    evaluate(formula, data) {
        var result = function(str) {
            // eslint-disable-next-line
            var data=this; // "data" is used in formula instead of "this"
            return eval(str);
        }.call(data, formula);

        return result;
    }    

}
