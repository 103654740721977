<template>

    <b-form-group :label-for="toID(field.name)" :label-class="labelClass">
        <template v-if="field.label" slot="label">
            <span v-html="field.label" />
            <mandatory-flag v-if="mandatory" />
            <info-button v-if="field.info"><div v-html="field.info" /></info-button>
        </template>

        <b-form-select ref="input" :id="toID(field.name)" :name="field.name" v-model="value" :options="options" :required="mandatory" :disabled="readonly" >
            <template slot="first">
                <option v-if="field.placeholder" :value="null">{{field.placeholder}}</option>
            </template>
        </b-form-select>

        <b-form-invalid-feedback>
            <div v-if="field.mandatoryMessage && !(value)" v-html="field.mandatoryMessage" />
            <div v-else-if="field.validationMessage && customError" v-html="field.validationMessage" />
        </b-form-invalid-feedback>
    </b-form-group>

</template>

<script>
    import { mapGetters } from 'vuex'

    import Field from '@/shared/utils/Field'
    import EvaluateCondition from '@/shared/utils/EvaluateCondition'

    export default {
        name: 'FormInput',

        props: {
            field: Object,
            data: Object,
            labelClass: String
        },

        data() {
            return {
                items: [],
                customError: false
            };
        },

        mounted: function () {
            if (this.field.values) {
                for (var i = 0; i < this.field.values.length; i++) {
                    var value = this.field.values[i];
                    var key = this.field.keys ? this.field.keys[i] : value;
                    this.items.push({ text: value, value: key });
                }
            }

            if (this.field.defaultValue) {
                this.value=this.field.defaultValue; // Store default value to the data
            }

            this.validate();
        },

        computed: {
            ...mapGetters(['lists']),

            value: {
                get: function () {
                    return Field.getValue(this.field.name, this.data, this.field.defaultValue)
                },

                set: function (newValue) {
                    if (newValue=="" || newValue==null) {
                        Field.clearField(this.field.name, this.data, this.$delete);
                    }
                    else {
                        Field.setValue(this.field.name, newValue, this.data, this.$set)
                    }

                    this.validate();
                }
            }, 

            options: function () {
                if (this.field.lov) {
                    return this.lists[this.field.lov]
                }

                if ((typeof this.field.items) === typeof(Function)) {
                   return this.field.items(this.field);
                }

                return this.items;
            },

            readonly: function () {
                if (this.field.readonly) {
                    return true;
                } 

                if (this.field.readonlyIf) {
                    return EvaluateCondition.evaluate(this.field.readonlyIf, this.data);
                }

                return false;
            },

            mandatory: function () {
                if (this.field.mandatory) {
                    return true;
                } 

                if (this.field.mandatoryIf) {
                    return EvaluateCondition.evaluate(this.field.mandatoryIf, this.data);
                }

                return false;
            }

        },

        methods: {
            validate() {
                if (this.field.validIf) {
                    var valid=EvaluateCondition.evaluate(this.field.validIf, this.data);
                    if (!valid) {
                        document.getElementById(this.toID(this.field.name)).setCustomValidity(this.field.validationMessage);
                        
                        this.customError=true;
                        return false;
                    }
                }

                document.getElementById(this.toID(this.field.name)).setCustomValidity("");
                this.customError=false;
                return true;
            }
        }

    }
</script>

<style lang="scss">
</style>
