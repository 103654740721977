import axios from 'axios'
import errorParser from '@/shared/utils/ErrorParser'

export default {
    /**
     * Popup was shown and closed
     */
    shown (popupID) {
        return new Promise((resolve, reject) => {
            axios.post('/popup/shown/'+popupID).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    }
}
