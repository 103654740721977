<template>
    <page-layout :loading="isLoading" hideOnLoading>
        
        <template v-if="data.deposit">
            <b-row class="align-items-center">
                <h1 class="col-12">
                    Festgeld Gesamtsumme

                    <b-dropdown dropright size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                            <i class="fas fa-share-square"></i>
                        </template>

                        <b-dropdown-item :href="api+'/csv/deposits/open'">CSV aktive Festgelder</b-dropdown-item>
                        <b-dropdown-item :href="api+'/csv/deposits/all'">CSV alle Festgelder</b-dropdown-item>
                    </b-dropdown>
                </h1>
            </b-row>

            <b-row class="cards">
                <div class="card-col col-md-6 col-lg-4 col-xl-3">
                    <b-card>
                        <div class="text-center">
                            Gesamtanlagebetrag
                        </div>
                        <big-amount :amount="data.deposit" class="text-center" />
                    </b-card>
                </div>

                <div class="card-col col-md-6 col-lg-4 col-xl-3">
                    <b-card>
                        <div class="text-center">
                            Alle Festgelder im Detail:
                        </div>
                        <b-button to="/deposit" size="sm" variant="primary" class="w-100 mt-1">FESTGELD</b-button>
                    </b-card>
                </div>
            </b-row>
        </template>
        <template v-else >
            <template v-if="user.userRoles['signatory']">
                <b-row class="align-items-center">
                    <h1 class="col-auto">
                        Festgeld

                        <b-dropdown v-if="data.hasFinishedDeposits" dropright  size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                                <i class="fas fa-share-square"></i>
                            </template>

                            <b-dropdown-item :href="api+'/csv/deposits/all'">historische Daten als CSV exportieren</b-dropdown-item>
                        </b-dropdown>
                    </h1>
                </b-row>

                <b-row>
                    <div class="col-lg-8 col-xl-6">
                        <b-card>
                            <b-row class="align-items-center">
                                <div class="col-md-6">
                                    Hier könen Sie die Veranlagung eines Festgeldes beantragen.
                                </div>

                                <div class="col-md-6 mt-3 mt-md-0">
                                    <b-button to="/deposit/new" size="sm" variant="primary" class="w-100">NEUES FESTGELD</b-button>
                                </div>
                            </b-row>
                        </b-card>
                    </div>
                </b-row>
            </template>            
            <b-row v-else>
                <h1 class="col-12">Festgeld online beantragen</h1>
                <div class="col-12"> 
                    <div class="card px">
                        Sie sind derzeit nicht für KOMMUNALKREDIT DIREKT Festgelder registriert. Möchten Sie jetzt ein Festgeld beantragen?<br>
                        Das ist ganz einfach über unsere Online-Antragsstrecke möglich:
                        <b-button :href="registrationURL" target="_new" size="sm" variant="primary" class="mt-2 btn-2">FESTGELD BEANTRAGEN</b-button>
                    </div>
                </div>
            </b-row>
            
        </template>

        <template v-if="data.financing">
            <b-row class="align-items-center mt-5">
                <h1 class="col-12">
                    Finanzierungen Gesamtsumme

                    <b-dropdown dropright size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                            <i class="fas fa-share-square"></i>
                        </template>

                        <b-dropdown-item :href="api+'/csv/financing/open'">CSV aktive Finanzierungen</b-dropdown-item>
                        <b-dropdown-item :href="api+'/csv/financing/all'">CSV alle Finanzierungen</b-dropdown-item>
                    </b-dropdown> 
                </h1>
            </b-row>

            <b-row class="cards">
                <div class="card-col col-md-6 col-lg-4 col-xl-3">
                    <b-card>
                        <div class="text-center">
                            Aushaftender Saldo
                        </div>
                        <big-amount :amount="data.financing" class="text-center" />
                    </b-card>
                </div>

                <div class="card-col col-md-6 col-lg-4 col-xl-3">
                    <b-card>
                        <div class="text-center">
                            Alle Finanzierungen im Detail:
                        </div>
                        <b-button to="/finance" size="sm" variant="primary" class="w-100 mt-1">FINANZIERUNGEN</b-button>
                    </b-card>
                </div>
            </b-row>
        </template>
        <template v-else >
            <b-row class="align-items-center mt-5">
                <h1 class="col-auto">
                    Finanzierungen

                    <b-dropdown v-if="data.hasFinishedFinancing" dropright size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                            <i class="fas fa-share-square"></i>
                        </template>

                        <b-dropdown-item :href="api+'/csv/financing/all'">historische Daten als CSV exportieren</b-dropdown-item>
                    </b-dropdown>
                </h1>
            </b-row>

            <b-row>
                <div class="col-lg-8 col-xl-6">
                    <b-card>
                        <b-row class="align-items-center">
                            <div class="col-md-7">
                                Sie interessieren sich für eine Finanzierung bei der Kommunalkredit?
                            </div>

                            <div class="col-md-5 mt-3 mt-md-0">
                                <b-button to="/request" size="sm" variant="primary" class="w-100">ANFRAGEN</b-button>
                            </div>
                        </b-row>
                    </b-card>
                </div>
            </b-row>
        </template>

        <b-modal v-for="popup in user.popups" :key="popup.id" :id="'info-modal-'+popup.id" :title="popup.subject" centered hide-footer no-close-on-backdrop 
                header-class='py-2 border-bottom-0' @close="closed(popup.id)">
            <div v-html="popup.body" />
        </b-modal>

    </page-layout>
</template>

<script>
    import PageLayout from '@/layouts/KontomanagerLayout.vue'
    import BigAmount from '@/components/BigAmount.vue'

    import productService from '@/services/product'
    import popupService from '@/services/popup'
    import { mapGetters } from 'vuex'

    export default {
        name: 'OverviewPage',

        components: {
            PageLayout, BigAmount
        },

        data() {
            return {
                isLoading: true,
                data: {},
                registrationURL: process.env.VUE_APP_REGISTRATION_URL
            };
        },

        computed: {
            ...mapGetters(['api', 'user'])
        },

        mounted: function() {
            productService.getOverview().then(data => {
                this.data=data;
                this.isLoading=false;

                if (this.user.popups && !this.user.popupShown) {
                    this.$nextTick(function () {
                        this.user.popups.forEach(popup => {
                            this.$bvModal.show('info-modal-'+popup.id);    
                        });
                        
                        this.user.popupShown=true;
                    })                   
                }
            }).catch((error) => {
                this.isLoading=false;
                this.showError(error.message)
            })
        }, 

        beforeRouteEnter (to, from, next) {
            if (!from.name) {
                // Page was refreshed and not selected from other page -> not show popups
                next(vm => {
                    vm.user.popupShown=true;
                })
            }
            else {
                next();
            }
        },

        methods : {

            closed(popupID) {
                popupService.shown(popupID);
            }

        }
    }
</script>
