import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex);

const state = {
    api: process.env.VUE_APP_API_ENDPOINT, // Server API endpoint url
    user: {
        name: null,
        authenticated: false
    },
    info: {}, // Info to show on info page
    expiration: 0,
    uuid: null
};

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
