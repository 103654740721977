import axios from 'axios'
import errorParser from '@/shared/utils/ErrorParser'

var interestRates;

export default {

    /**
     * Get overview data
     */
    getOverview() {
        return new Promise((resolve, reject) => {
            axios.get('/overview').then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },
    
    /**
     * Get deposit (Fesgeld) list
     */
    getDeposits(all=false) {
        return new Promise((resolve, reject) => {
            axios.get('/deposits/'+(all ? 'all' : 'open')).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },
    
    /**
     * Get product data
     */
    getProduct(productID) {
        return new Promise((resolve, reject) => {
            axios.get('/product/'+productID).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },
    
    /**
     * Get financing (Finanzierung) list
     */
    getFinancing(all=false) {
        return new Promise((resolve, reject) => {
            axios.get('/financing/'+(all ? 'all' : 'open')).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },
    
    /**
     * Update product description
     * @param {*} productID 
     * @param {*} description 
     */
    updateProductDescription(productID, description) {
        return new Promise((resolve, reject) => {
            axios.post('/product/description/'+productID, {description}).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Get interest rates
     */
    getInterestRates() {
        return new Promise((resolve, reject) => {
            if (interestRates) {
                resolve(interestRates);
            }
            else {
                axios.get('/interest').then(({data}) => {
                    interestRates=data;
                    resolve(data);
                }).catch((error) => {
                    reject(errorParser.parse(error));
                });
            }
        });
    },

    /**
     * Get account statements (Finanzierung) list
     * @param {*} productID 
     */
    getAccountStatements(productID) {
        return new Promise((resolve, reject) => {
            axios.post('/financing/accountstatements/'+productID).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Reinvest deposit
     * @param {*} productID 
     */
    reinvest(productID, data) {
        return new Promise((resolve, reject) => {
            axios.post('/deposit/reinvest/'+productID, data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Reinvest deposit confirm
     * @param {*} productID 
     */
    reinvestConfirm(productID, data) {
        return new Promise((resolve, reject) => {
            axios.post('/deposit/reinvest/confirm/'+productID, data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Reinvest deposit reject
     * @param {*} productID 
     */
    reinvestReject(productID, data) {
        return new Promise((resolve, reject) => {
            axios.post('/deposit/reinvest/reject/'+productID, data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Storno deposit
     * @param {*} productID 
     */
    storno(productID, data) {
        return new Promise((resolve, reject) => {
            axios.post('/deposit/storno/'+productID, data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Storno deposit confirm
     * @param {*} productID 
     */
    stornoConfirm(productID, data) {
        return new Promise((resolve, reject) => {
            axios.post('/deposit/storno/confirm/'+productID, data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    },

    /**
     * Storno deposit reject
     * @param {*} productID 
     */
    stornoReject(productID, data) {
        return new Promise((resolve, reject) => {
            axios.post('/deposit/storno/reject/'+productID, data).then(({data}) => {
                resolve(data);
            }).catch((error) => {
                reject(errorParser.parse(error));
            });
        });
    }

}
