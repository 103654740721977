<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "App"
    }
</script>


<style lang="scss">
    
    /* test styles */

    .bb {
        border: blue 1px solid !important;
    }

    .br {
        border: red 1px solid !important;
    }

    /* Common classes */

    .mt--3 {
       margin-top: -1rem !important;
    }

    .mb--3 {
       margin-bottom: -1rem !important;
    }
        
</style>
