<template>
    <div>{{value}}</div>
</template>

<script>
    import Field from '@/shared/utils/Field'

    export default {
        name: 'FormSimpleText',

        props: {
            field: Object,
            data: Object,
        },

        data() {
            return {
                customError: false
            };
        },

        computed: {
            value: function () {
                return Field.getValue(this.field.name, this.data, this.field.defaultValue)
            }

        },

        mounted: function () {
            if (this.field.defaultValue) {
                Field.setValue(this.field.name, this.field.defaultValue, this.data, this.$set); // Store default value to the data
            }
        }
    }
</script>

<style lang="scss">
</style>
