import userService from '@/services/user'

export const logout = ({ commit }) => {
    commit('logout')
}

export const getUserData = ({ commit }) => {
    return new Promise((resolve, reject) => {
        userService.getUser().then(data => {
            commit('updateUserData', data);
            resolve();
        }).catch((error) => {
            reject(error);
        })
    })
}

export const setInfo = ({ commit }, info) => {
    commit('info', info)
}

export const expiration = ({ commit }) => {
    commit('expiration')
}
