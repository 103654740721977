<template>
    <div>

        <form-radio v-if="fieldType=='radio'" :field="localField" :data="data" v-on="$listeners" />
        <form-check v-else-if="fieldType=='check'" :field="localField" :data="data" v-on="$listeners" />
        <form-select v-else-if="fieldType=='combo'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-upload v-else-if="fieldType=='upload'" :field="localField" :data="data" :attachments="attachments" :labelClass="labelClass" v-on="$listeners" />
        <form-group-field v-else-if="fieldType=='group'" :field="localField" :data="data" :attachments="attachments" v-on="$listeners" />
        <form-simple-text v-else-if="fieldType=='simple'" :field="localField" :data="data" :labelClass="labelClass" />
        <form-date v-else-if="fieldType=='date'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-number v-else-if="fieldType=='number'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-timestamp v-else-if="fieldType=='timestamp'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-IBAN v-else-if="fieldType=='iban'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-text-area v-else-if="fieldType=='area'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-list v-else-if="fieldType=='list'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-condition v-else-if="fieldType=='condition'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-html v-else-if="fieldType=='html'" :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />
        <form-input v-else :field="localField" :data="data" :labelClass="labelClass" v-on="$listeners" />

    </div>
</template>

<script>
    import FormInput from '@/shared/components/forms/FormInput'
    import FormRadio from '@/shared/components/forms/FormRadio'
    import FormCheck from '@/shared/components/forms/FormCheck'
    import FormSelect from '@/shared/components/forms/FormSelect'
    import FormGroupField from '@/shared/components/forms/FormGroupField'
    import FormUpload from '@/shared/components/forms/FormUpload'
    import FormSimpleText from '@/shared/components/forms/FormSimpleText'
    import FormDate from '@/shared/components/forms/FormDate'
    import FormNumber from '@/shared/components/forms/FormNumber'
    import FormTimestamp from '@/shared/components/forms/FormTimestamp'
    import FormIBAN from '@/shared/components/forms/FormIBAN'
    import FormTextArea from '@/shared/components/forms/FormTextArea'
    import FormList from '@/shared/components/forms/FormList'
    import FormCondition from '@/shared/components/forms/FormCondition'
    import FormHtml from '@/shared/components/forms/FormHtml'

    import EvaluateCondition from '@/shared/utils/EvaluateCondition'

    export default {
        name: 'FormField',
        components: { FormInput, FormRadio, FormCheck, FormSelect, FormGroupField, FormUpload, FormSimpleText, FormDate, FormNumber, FormIBAN, FormTextArea, FormTimestamp, FormList, FormCondition, FormHtml },

        props: {
            field: Object,
            data: Object,
            attachments: Object,
            labelClass: String
        },

        data() {
            return {
                localField: this.field
            };
        },

        computed: {
            fieldType() {
                switch (this.localField.type) {
                    case "E": return "email";
                    case "N": return "number";
                    case "D": return "date";
                    case "F": return "timestamp";
                    case "H": return "html";
                    case "R": return "radio";
                    case "K": return "check";
                    case "C": return "combo";
                    case "G": return "group";
                    case "U": return "upload";
                    case "S": return "simple";
                    case "B": return "iban";
                    case "X": return "area";
                    case "L": return "list";
                    case "c": return "condition";
                    case "W": return "password";

                    // TODO
                    case "J": return "table";
                    case "A": return "autocomplete";
                    case "P": return "phone";
                    case "M": return "mobile";
                }

                return "text";
            }
        },

        mounted() {
            this.configChange();
        },

        methods: {

             configChange: function () { // watch it
                // Conditional configuration change
                if (this.localField.changes) {
                    for (var change of this.localField.changes) {
                        var condition = EvaluateCondition.evaluate(change.condition, this.data);
                        var value = condition ? change.fieldValueTrue : change.fieldValueFalse;
                        this.$set(this.localField, change.fieldName, value)
                        //console.log("Configuration changed " + change.fieldName + " to " + value)
                    }

                    this.$forceUpdate();
                }
            }

        }
    }
</script>

<style lang="scss">
</style>
