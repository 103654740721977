<template>
    <div id="page" class="min-vh-100 d-flex flex-column">
        <loading loader="dots" :active="loading" :is-full-page="true"></loading>

        <slot name="header">
            <header id="header" class="d-print-none">
                <b-row id="header-top" align-v="center">
                    <img id="header-logo" class="col-auto order-1" src="/online/images/logo_direct.svg" alt="Logo" />
                    <template v-if="user.authenticated">
                        <div class="w-100 mt-3 order-3 order-md-2 d-block d-md-none">
                        </div>
                        <div class="col-auto ml-auto order-3 order-md-2">
                            <a href="mailto:kundenservice@kommunalkreditdirekt.at?subject=Pers%F6nliche%20Anfrage%20aus%20Online%20Banking" class="row align-items-center">
                                <div class="col-auto pl-0 pr-2 text-right">PERSÖNLICHE<br>ANFRAGE</div>
                                <i class="far fa-comments fa-2x col-auto px-0"></i>
                            </a>
                        </div>
                        <!--div class="col-auto order-4 order-md-3">
                            <a href="#" class="row align-items-center">
                                <div class="col-auto pr-2 text-right">ALLGEMEINES<br>POSTFACH</div>
                                <i class="fas fa-envelope fa-2x col-auto px-0"></i>
                            </a>
                        </div-->
                        <div class="col-auto order-2 order-md-4 ml-auto ml-md-0">
                            <a v-if="user.authenticated" href="#" @click="logout()" class="row align-items-center">
                                <div class="col-auto pr-2">LOGOUT<session-countdown @expired="logout()" /> </div>
                                <i class="fas fa-sign-out-alt fa-2x col-auto px-0"></i>
                            </a>
                        </div>
                    </template>
                    <slot v-else name="menu">
                        <div class="col-auto ml-auto mr-3 order-2">
                            <b-link to="/login" class="row align-items-center">
                                <div class="col-auto pr-2" >LOGIN</div>
                                <i class="fas fa-sign-in-alt fa-2x col-auto p-0"></i>
                            </b-link>
                        </div>
                    </slot>
                </b-row>

                <b-navbar v-if="user.authenticated" id="header-bottom" toggleable="lg" type="dark">
                    <b-container>
                        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                        <b-collapse id="nav-collapse" is-nav>
                            <b-navbar-nav>
                                <b-nav-item to="/overview" active-class="active">Finanzübersicht</b-nav-item>
                                <b-nav-item to="/finance" active-class="active">Finanzierung</b-nav-item>
                                <b-nav-item to="/deposit" active-class="active">Festgeld</b-nav-item>
                                <b-nav-item to="/docs" active-class="active">Bedingungen & Formulare</b-nav-item>
                                <b-nav-item to="/user" active-class="active">Benutzerdaten</b-nav-item>
                            </b-navbar-nav>
                        </b-collapse>
                    </b-container>
                </b-navbar>
            </header>
        </slot>

        <slot name="navigation" />

        <div id="content" class="flex-grow-1">
            <b-container :fluid="fluid">
                <slot v-if="!hideOnLoading || !loading" />
            </b-container>
        </div>

        <slot name="footer">
            <div id="footer" class="d-print-none">
                <div class="links">
                    <a href="https://www.kommunalkreditdirekt.at/impressum/">Impressum</a> | <a href="https://www.kommunalkreditdirekt.at/datenschutz/">Datenschutz</a>
                </div>
                <div class="copyright">
                    Kommunalkredit Austria AG {{year}}
                </div>
            </div>
        </slot>

        <bootstrap-helper />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import userService from '@/services/user'
    import BootstrapHelper from '@/shared/components/BootstrapHelper.vue'
    import SessionCountdown from '@/components/SessionCountdown.vue'
    import moment from 'moment';

    import Loading from 'vue-loading-overlay';

    export default {
        name: 'KontomanagerLayout',

        props: {
            header: String,
            fluid: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            hideOnLoading: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                year: moment().year()
            };
        },

        computed: {
            ...mapGetters(['user'])
        },

        components: {
            BootstrapHelper, Loading, SessionCountdown
        },

        methods: {
            logout() {
                userService.logout().then(() => {
                    this.$store.dispatch('logout');
                    this.$router.push({ name: 'logout' });
                });
            }
        }
    }
</script>

<style lang="scss">
    /* Colors */

    $primary: #019670 !default;
    $secondary: #DFE1E0 !default;
    $success: #28a745 !default;
    $info: #d9efea !default;
    $warning: #ffc107 !default;
    $danger: #dc3545 !default;
    $light: #f8f9fa !default;
    $dark: #343a40 !default;

    $link-color: #019670;
    $hover-color: #005b43;
    $active-color: #99d5c6;
    $h-color: #000000;
    $text-color: #353535;
    $placeholder-color: #a8a7a7;
    $background-color-dark: #e0e0e0;
    $background-color-light: #f8f9fa;

    $radius: 0.5rem;

    // Include Bootstrap and BootstrapVue SCSS files
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~bootstrap-vue/src/index.scss";

    /* Layout */

    body {
        font-family: "Roboto Regular";
        font-size: 15px;
        color: $text-color;
        min-height: 100vh;
        background-color: $background-color-dark;
        background: linear-gradient(
            90deg,
            $background-color-dark 0%,
            $background-color-light 25%,
            $background-color-dark 50%,
            $background-color-light 75%,
            $background-color-dark 100%
        );
    }

    // SM Small devices (landscape phones, 576px and up)
    // MD Medium devices (tablets, 768px and up)
    @media (max-width: 991px) {
        .container {
            max-width: 100%;
        }
    }

    /* header */

    #header {
        margin-bottom: -20px;
        background-color: white;
        background-image: none;
    }

    #header-top {
        min-height: 115px;
        display: flex;
        padding: 28px 20px;
        margin: 0;
    }

    #header-bottom {
        min-height: 60px;
        background-image: linear-gradient(to bottom, $hover-color, $primary);
    }

    #header-logo {
        height: 59px;
        padding: 0;
        margin-left: 0;
    }

    // LG Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        #header-logo {
            margin-left: 103px;
        }
    }

    #header-title {
        font-family: "Roboto Bold";
        font-size: 28px;
        line-height: 59px;
        color: $primary;
        white-space: nowrap;
    }

    .navbar-nav .nav-item {
        margin-right: 55px;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: $white;
    }

    .navbar-dark .navbar-nav .nav-link.active {
        color: $active-color;
    }

    /* footer */

    #footer {
        height: 128px;
        background-image: linear-gradient(to bottom, $hover-color, $primary);
        text-align: center;
        padding: 30px;

        font-family: "Roboto Bold";
        font-size: 18px;
        color: $light;
        white-space: nowrap;
    }

    #footer a:hover {
        color: #00ae8a;
    }

    #footer a {
        font-family: "Roboto Bold";
        font-size: 16px;
        color: $light;
        margin-left: 2px;
        margin-right: 2px;
    }

    #footer .copyright {
        margin-top: 15px;
    }

    /* content */

    #content {
        margin-top: 45px;
        margin-bottom: 30px;
        padding-left: 0;
        padding-right: 0;
    }

    hr {
        color: #d1d1d1;
        margin-top: 40px;
        margin-bottom: 55px;
    }

    /* texts */

    h1 {
        font-family: "Roboto Bold";
        font-size: 24px;
        color: $h-color;
    }

    h2 {
        font-family: "Roboto Bold";
        font-size: 15px;
        color: $h-color;
    }

    a {
        font-family: "Roboto Regular";
        font-size: 16px;
        color: $link-color;
    }

    a:hover {
        color: $hover-color;
        text-decoration: none;
    }

    #header a {
        font-weight: bold;
        line-height: 1rem;
    }

    /* Forms */

    label {
        min-height: 22px;
    }

    .label {
        font-size: 0.75rem;
    }

    .bold {
        font-weight: bold;
    }

    .btn {
        font-family: Roboto Bold;
        font-size: 16px;
        text-align: center;
        vertical-align: middle;
        text-transform: uppercase;
        padding: 10px 20px;
        border-radius: $radius;
    }

    /* Button in the same row as component */
    .btn-row {
        margin-top: 30px;
    }

    .btn-sm {
        font-size: 14px;
        min-height: 28px;
        padding: 5px 20px;
    }

    .btn-0 {
        min-height: 55px;
        line-height: 33px;
    }

    .btn-1 {
        min-height: 55px;
        min-width: 183px;
        line-height: 33px;
    }

    .btn-2 {
        width: 244px;
    }

    // SM Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
        .btn-sm-1 {
            min-width: 183px;
        }

        .btn-row-sm {
            margin-top: 30px;
        }
    }

    // MD Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        .btn-md-1 {
            min-width: 183px;
        }

        .btn-row-md {
            margin-top: 30px;
        }
    }

    .btn-primary {
        color: $light;
        background-image: linear-gradient(to left, $hover-color, $primary);
    }

    .btn-primary:hover:enabled {
        color: $light;
        background-image: linear-gradient(to right, $hover-color, $primary);
    }

    .btn-secondary {
        color: $text-color;
        background-image: linear-gradient(to right, #dfdfdf, #cfcfcf);
        border: none;
    }

    .dropdown button {
        min-width: inherit;
        color: #a0a0a0;
        padding-left: 10px;
        padding-top: 6px;
    }

    /* Forms */ 

    .form-page > .form-group:last-child {
        margin-bottom: 0;
    }

    input.form-control, .custom-select {
        height: 55px;
        border-radius: $radius;
    }

    .custom-select.sort {
        background-color: $secondary;
        width: 183px;
        height: 28px;
        font-size: 0.8rem;
        line-height: 0.8rem;
    }

    .form-control {
        border-radius: $radius;
    }

    .input-group-text {
        border-radius: $radius;
    }

    span.mandatory {
        color: $text-color !important;
    }

    .hide-mandatory span.mandatory {
        display: none;
    }

    mt--2 {
        margin-top: -10px !important;
    }

    /* Strange radiobuttons */

    .custom-control {
        padding-left: 40px;
    }

    .custom-control-label {
        padding-top: 6px;
        min-height: 34px;
    }

    .custom-radio .custom-control-label::before, .custom-checkbox .custom-control-label::before {
        border-radius: 20%;
    }

    .custom-control-label::before {
        border-radius: 20%;
        width: 34px;
        height: 34px;
        top: 0;
        left: -40px;
    }

    .custom-control-label::after {
        width: 34px;
        height: 34px;
        top: 0;
        left: -40px;
        background: no-repeat 50%/50% 50%;
    }

    /* Round corners */

    .rounded {
        border-radius: $radius !important;
    }

    .rounded-right {
        border-top-right-radius: $radius !important;
        border-bottom-right-radius: $radius !important;
    }

    /* Cards */

    .cards {
        margin-right: -5px;
        margin-left: -5px;
    }

    .cards .card {
        margin-bottom: 10px;
        flex-grow: 1;
    }

    .card-col {
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
    }

    .card {
        padding-top: 21px;
        padding-bottom: 21px;
        border-radius: $radius;
    }

    .card-body {
        padding: 0 15px;
    }

    .px {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* Placeholders */

    .form-control::-webkit-input-placeholder,
    .form-control::placeholder {
        color: $placeholder-color;
    }

    /* Invalid feedback */

    .b-form-file.custom-error ~ .invalid-feedback a {
        color: $danger;
    }

    .custom-invalid-feedback {
        display: none;
        width: 100%;
        font-size: 80%;
        color: $danger;
    }

    .was-validated .custom-invalid-feedback {
        display: block;
    }

    .was-validated .custom-control-input:invalid ~ .custom-control-label,
    .custom-control-input.is-invalid ~ .custom-control-label {
        color: $text-color;
    }

    .was-validated .custom-control-input:valid ~ .custom-control-label,
    .custom-control-input.is-valid ~ .custom-control-label {
        color: $text-color;
    }

    .link-secondary {
        color: $text-color;
        text-decoration: underline;
    }

    /* small tables padding */

    .table-sm th,
    .table-sm td {
        padding: 0.2rem;
    }

    /* Loading dots */

    .vld-overlay {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        align-items: center;
        display: none;
        justify-content: center;
        overflow: hidden;
        z-index: 1;
    }

    .vld-overlay.is-active {
        display: flex;
    }

    .vld-overlay.is-full-page {
        z-index: 999;
        position: fixed;
    }

    .vld-overlay .vld-background {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        background: #fff;
        opacity: 0.5;
    }

    .vld-overlay .vld-icon,
    .vld-parent {
        position: relative;
    }

    /* print grid classes */

    @media print {
        .col-print {
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-print-auto {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        .col-print-1 {
            -ms-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
            max-width: 8.333333%;
        }
        .col-print-2 {
            -ms-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
            max-width: 16.666667%;
        }
        .col-print-3 {
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-print-4 {
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
        .col-print-5 {
            -ms-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
            max-width: 41.666667%;
        }
        .col-print-6 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-print-7 {
            -ms-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
        }
        .col-print-8 {
            -ms-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
            max-width: 66.666667%;
        }
        .col-print-9 {
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-print-10 {
            -ms-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
            max-width: 83.333333%;
        }
        .col-print-11 {
            -ms-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
            max-width: 91.666667%;
        }
        .col-print-12 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .pr-print-1, .px-print-1 {
            padding-right: 0.25rem !important;
        }
        .pl-print-1, .px-print-1 {
            padding-left: 0.25rem !important;
        }
    }

    /* session countdow timer */

    .session-countdown {
        color: $primary;
        font-size: 0.9em;
    }

    .session-countdown.expiring {
        color: $primary;
    }

    // SM Small devices (landscape phones, 576px and up)
    // MD Medium devices (tablets, 768px and up)
    // LG Large devices (desktops, 992px and up)
    // XL Extra large devices (large desktops, 1200px and up)
</style>
