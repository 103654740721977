<template>
    <div class="form-group" :class="group.class" >
        <hr v-if="group.hr" />
        <h1 v-if="group.title">{{ group.title }}
            <info-button v-if="group.info"><div v-html="group.info" /></info-button>
        </h1>

        <vue-render v-if="group.header" :code="group.header" :data="data" :uuid="uuid" :api="api" />

        <form-row v-for="row in visibleRows" :key="row.key" :row="row" :fields="group.fields" :data="data" :attachments="attachments" :buttons="buttons" v-on="$listeners" />

        <vue-render v-if="group.footer" :code="group.footer" :data="data" :uuid="uuid" :api="api" />

    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import FormRow from '@/shared/components/forms/FormRow'

    import EvaluateCondition from '@/shared/utils/EvaluateCondition'
    import Field from '@/shared/utils/Field'

    export default {
        name: 'FormGroup',

        props: {
            group: Object,
            buttons: Object,
            data: Object,
            attachments: Object
        },

        components: { FormRow },

        mounted: function () {
            if (this.group.rows) {
                for (var i=0; i<this.group.rows.length; i++) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.group.rows[i].key=i; // Unique row ID
                }
            }
        },

        computed: {

            ...mapGetters(['uuid', 'api']),

            visibleRows: function () {
                if (this.group.rows) {
                    return this.group.rows.filter(row => {
                        if (row.visibleIf) {
                            var visible=EvaluateCondition.evaluate(row.visibleIf, this.data);

                            if (!visible && (row.clearInvisibleFields || row.clearInvisibleFields==undefined)) {
                                Field.clearFields(Field.getRowFieldNames(row, this.group.fields), this.data, this.$delete);
                                Field.deleteUploads(Field.getRowUploadFieldNames(row, this.group.fields), this.uuid, this.attachments, this.$delete);
                            }

                            return visible;
                        }

                        return true;
                    });
                }

                return [];
            }

        },

        methods: {

            visibleCols: function (row) {
                if (row.cols) {
                    return row.cols.filter(col => {
                        if (col.visibleIf) {
                            var visible=EvaluateCondition.evaluate(col.visibleIf, this.data);

                            if (!visible && col.fieldName && (col.clearInvisibleFields || col.clearInvisibleFields==undefined)) {
                                Field.clearField(this.group.fields[col.fieldName].name, this.data, this.$delete);
                                if (this.group.fields[col.fieldName].type=='U') {
                                    Field.deleteUpload(this.group.fields[col.fieldName].name, this.uuid, this.attachments, this.$delete);
                                }
                            }

                            return visible;
                        }

                        return true;
                    });
                }

                return [];
            }

        }
    }
</script>

<style lang="scss">
</style>
