<template>
    <page-layout>
        <h1>Something went wrong!</h1>
    </page-layout>
</template>

<script>
    import PageLayout from '@/layouts/KontomanagerLayout.vue'

    export default {
        name: 'ErrorPage',

        components: {
            PageLayout
        }
    }
</script>
