<template>
    <div id="bootstrap" class="d-print-none" style="position: fixed; bottom: 0px; right: 0px; background-color: silver; margin: 2px; padding: 2px;">
        <span class="d-block d-sm-none">XS</span> 
        <span class="d-none d-sm-block d-md-none">SM</span> 
        <span class="d-none d-md-block d-lg-none">MD</span>
        <span class="d-none d-lg-block d-xl-none">LG</span>
        <span class="d-none d-xl-block">XL</span>
    </div>

</template>

<script>
    export default {
        name: 'BootstrapHelper',
    }
</script>

<style lang="scss" scoped>
</style>
