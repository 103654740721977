<template>
    <b-row :class="row.class" >
        <form-column v-for="col in visibleCols(row)" :key="col.key" :col="col" :fields="fields" :data="data" :attachments="attachments" :buttons="buttons" v-on="$listeners" />
    </b-row>
</template>

<script>
    import { mapGetters } from 'vuex'

    import FormColumn from '@/shared/components/forms/FormColumn'

    import EvaluateCondition from '@/shared/utils/EvaluateCondition'
    import Field from '@/shared/utils/Field'

    export default {
        name: 'FormRow',

        props: {
            row: Object,
            buttons: Object,
            fields: Object,
            data: Object,
            attachments: Object
        },

        components: { FormColumn },

        computed: {
            ...mapGetters(['uuid'])
        },

        mounted: function () {
            if (this.row.cols) {
                for (var i=0; i<this.row.cols.length; i++) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.row.cols[i].key=i; // Unique column ID
                }
            }
        },

        methods: {

            visibleCols: function (row) {
                if (row.cols) {
                    return row.cols.filter(col => {
                        if (col.visibleIf) {
                            var visible=EvaluateCondition.evaluate(col.visibleIf, this.data);

                            if (!visible && col.fieldName && (col.clearInvisibleFields || col.clearInvisibleFields==undefined)) {
                                Field.clearField(this.fields[col.fieldName].name, this.data, this.$delete);
                                if (this.fields[col.fieldName].type=='U') {
                                    Field.deleteUpload(this.fields[col.fieldName].name, this.uuid, this.attachments, this.$delete);
                                }
                            }

                            return visible;
                        }

                        return true;
                    });
                }

                return [];
            }

        }
    }
</script>

<style lang="scss">
</style>
