<template>

    <b-form-group :label-for="toID(field.name)" :label-class="labelClass" >
        <template v-if="field.label" slot="label">
            <span v-html="field.label" />
            <mandatory-flag v-if="mandatory" />
            <info-button v-if="field.info"><div v-html="field.info" /></info-button>
        </template>

        <template slot="description">{{field.description}}</template>

        <b-form-input ref="input" :id="toID(field.name)" v-model="value" type="text" :required="mandatory" :readonly="readonly" :pattern="field.format" :placeholder="field.placeholder" :maxlength="field.maxLength" @keydown.enter.prevent="$emit('enter')" />
        <b-form-invalid-feedback >
            <div v-if="field.mandatoryMessage && !(value)" v-html="field.mandatoryMessage" />
            <div v-else-if="field.formatMessage && (formatError || htmlError)" v-html="field.formatMessage" />
            <div v-else-if="field.validationMessage && customError" v-html="field.validationMessage" />
        </b-form-invalid-feedback>
    </b-form-group>

</template>

<script>
    import IBAN from 'iban';

    import Field from '@/shared/utils/Field'
    import EvaluateCondition from '@/shared/utils/EvaluateCondition'

    export default {
        name: 'FormIBAN',

        props: {
            field: Object,
            data: Object,
            labelClass: String
        },

        data() {
            return {
                customError: false, 
                formatError: false,
                htmlError: false
            };
        },

        computed: {
            value: {
                get: function () {
                    return this.getValue() 
                },

                set: function (newValue) {
                    this.setValue(newValue);
                }
            },

            readonly: function () {
                if (this.field.readonly) {
                    return true;
                } 

                if (this.field.readonlyIf) {
                    return EvaluateCondition.evaluate(this.field.readonlyIf, this.data);
                }

                return false;
            },

            mandatory: function () {
                if (this.field.mandatory) {
                    return true;
                } 

                if (this.field.mandatoryIf) {
                    return EvaluateCondition.evaluate(this.field.mandatoryIf, this.data);
                }

                return false;
            }

        },

        mounted: function () {
            this.value=this.getValue();

            if (this.field.defaultValue) {
                this.setValue(this.value); // Store default value to the data
            }
        },
        
        methods: {

            getValue() {
                var value=Field.getValue(this.field.name, this.data, this.field.defaultValue);
                return value;
            },

            setValue(newValue) {
                if (newValue=="" || newValue==null) {
                    Field.clearField(this.field.name, this.data, this.$delete);
                    this.htmlError=false;
                }
                else {
                    var valid=IBAN.isValid(newValue); 

                    if (!valid) {
                        document.getElementById(this.toID(this.field.name)).setCustomValidity(this.field.validationMessage);
                        this.formatError=true;
                        //Field.clearField(this.field.name, this.data, this.$delete);
                        Field.setValue(this.field.name, newValue, this.data, this.$set)
                        return false;
                    }

                    var validity=this.$refs.input.validity;
                    this.htmlError=validity.typeMismatch || validity.patternMismatch;

                    this.formatError=false;
                }

                Field.setValue(this.field.name, newValue, this.data, this.$set)

                return this.validate();
            },

            validate() {
                if (!this.formatError && this.field.validIf) {
                    var validity=this.$refs.input.validity;
                    
                    if (!validity.typeMismatch && !validity.patternMismatch) {
                        var valid=EvaluateCondition.evaluate(this.field.validIf, this.data);
                        if (!valid) {
                            document.getElementById(this.toID(this.field.name)).setCustomValidity(this.field.validationMessage);
                            this.customError=true;
                            return false;
                        }
                    }
                }

                document.getElementById(this.toID(this.field.name)).setCustomValidity("");
                this.customError=false;
                return true;
            }

        }
    }
</script>

<style lang="scss">
</style>
