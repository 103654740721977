<template>
    <span class="info text-primary d-print-none">
        <a :id="id" tabindex="-1" @click.prevent="" href="#">
            <i class="fas fa-info-circle"></i>
        </a>
        <b-popover :target="id" :title="title" triggers="focus hover" >
            <slot />
        </b-popover>
    </span>
</template>

<script>
    export default {
        name: 'InfoButton',

        props: {
            title: String
        },

        computed: {
            id() {
                return "info-"+this._uid
            }
        }
    }
</script>

<style lang="scss">
    span.info {
        margin-left: 5px;
    }

    span.info a {
        font-size: 1em;
    }
</style>
