<template>
    <button @click="click" class="btn" :class="button.class" :title="button.title">
        <i v-if="button.icon" :class="button.icon" />
        {{button.label}}
        <i v-if="button.iconRight" :class="button.iconRight" />
    </button>
</template>

<script>
    export default {
        name: 'FormButton',

        props: {
            button: Object
        },

        methods: {

            click() {
                switch (this.button.action) {
                    case "link": window.open(this.button.parameter, "_self"); break; // Open link
                    case "script": eval(this.button.parameter); break;               // Execute Javascript
                    default: this.$emit('button', this.button);                      // Else sent event to the parent
                }                
            }

        }

    }
</script>

<style lang="scss">
</style>
