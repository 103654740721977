import * as moment from 'moment';

export default {

    formatNumber: (value, decimal=2, useGrouping=true) => {
        if (!value) {
            value=0;
        }
        
        return new Intl.NumberFormat('de-DE', {minimumFractionDigits: decimal, maximumFractionDigits: decimal, useGrouping}).format(value);
    },

    formatDate: (value, format='DD.MM.YYYY') => {
        if (value) {
            return moment(String(value)).format(format);
        }
    },

    formatDateAddDays: (value, days, format='DD.MM.YYYY') => {
        if (value) {
            return moment(String(value)).add(days, 'days').format(format);
        }
    },

    formatDateTimne: (value, format='DD.MM.YYYY HH:mm:ss') => {
        if (value) {
            return moment(String(value)).format(format);
        }
    },

    formatFileSize: (size) => {
        if (!size) {
            return null;
        }

        var unit = ['B', 'KB', 'MB', 'GB']
        var index = 0;

        while (index < 4) {
            if (size < 1024) {
                return size.toFixed(0) + " " + unit[index];
            }
            size = size / 1024;
            index++;
        }

        return size.toFixed(2) + " TB";
    },

    twoDigits(value) {
        const text=value.toString();
        
        if (text.length <= 1) {
            return '0' + text;
        }

        return text;
    }
    
}

