<template>
    <div class="form-page" :class="page.class" >
        <vue-render v-if="page.header" :code="page.header" :data="data" :uuid="uuid" :api="api" />

        <template v-for="group in visibleGroups">
            <form-group :group="group" :key="group.name" :data="data" :attachments="attachments" :buttons="buttons" v-on="$listeners" />
        </template>

        <vue-render v-if="page.footer" :code="page.footer" :data="data" :uuid="uuid" :api="api" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import FormGroup from '@/shared/components/forms/FormGroup.vue'

    import EvaluateCondition from '@/shared/utils/EvaluateCondition'
    import Field from '@/shared/utils/Field'

    export default {
        name: 'FormPage',

        props: {
            page: Object,
            buttons: Object,
            data: Object,
            attachments: Object
        },

        components: { 
            FormGroup
        },
        
        computed: {
            ...mapGetters(['uuid', 'api']),
            
            visibleGroups: function () {
                if (this.page.groups) {
                    return this.page.groups.filter(group => {
                        if (group.visibleIf) {
                            var visible=EvaluateCondition.evaluate(group.visibleIf, this.data);

                            if (!visible && (group.clearInvisibleFields || group.clearInvisibleFields==undefined)) {
                                Field.clearFields(Field.getGroupFieldNames(group), this.data, this.$delete);
                                Field.deleteUploads(Field.getGroupUploadFieldNames(group), this.uuid, this.attachments, this.$delete);
                            }

                            return visible;
                        }

                        return true;
                    });
                }

                return [];
            }

        }
    }
</script>

<style lang="scss">
</style>
