<template>
    <div :class="col.class">
        <vue-render v-if="col.header" :code="col.header" :data="data" :uuid="uuid" :api="api" />

        <form-row v-for="row in visibleRows" :key="row.key" :row="row" :fields="fields" :data="data" :attachments="attachments" :buttons="buttons" v-on="$listeners" />

        <form-field v-if="col.fieldName && fields[col.fieldName]" :field="fields[col.fieldName]" :key="col.fieldName" :data="data" :attachments="attachments" :labelClass="col.labelClass" v-on="$listeners" />
        <div v-if="col.fieldName && !fields[col.fieldName]" class="br" :class="col.class">Field {{col.fieldName}} not found!</div>

        <form-button v-if="col.button" :button="buttons[col.button]" v-on="$listeners" />
        <vue-render v-if="col.footer" :code="col.footer" :data="data" :uuid="uuid" :api="api" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    
    import FormField from '@/shared/components/forms/FormField'
    import FormButton from '@/shared/components/forms/FormButton'

    import EvaluateCondition from '@/shared/utils/EvaluateCondition'
    import Field from '@/shared/utils/Field'

    export default {
        name: 'FormColumn',

        props: {
            col: Object,
            buttons: Object,
            fields: Object,
            data: Object,
            attachments: Object
        },

        components: {
             FormField, FormButton,  
             FormRow: () => import('@/shared/components/forms/FormRow'), // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
        },

        mounted: function () {
            if (this.col.rows) {
                for (var i=0; i<this.col.rows.length; i++) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.col.rows[i].key=i; // Unique row ID
                }
            }
        },

        computed: {

            ...mapGetters(['uuid', 'api']),

            visibleRows: function () {
                if (this.col.rows) {
                    return this.col.rows.filter(row => {
                        if (row.visibleIf) {
                            var visible=EvaluateCondition.evaluate(row.visibleIf, this.data);

                            if (!visible && (row.clearInvisibleFields || row.clearInvisibleFields==undefined)) {
                                Field.clearFields(Field.getRowFieldNames(row, this.fields), this.data, this.$delete);
                                Field.deleteUploads(Field.getRowUploadFieldNames(row, this.fields), this.uuid, this.attachments, this.$delete);
                            }

                            return visible;
                        }

                        return true;
                    });
                }

                return [];
            }

        }

    }
</script>

<style lang="scss">
</style>
