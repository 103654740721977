<template>

    <b-form-group :label-for="toID(field.name)" :label-class="labelClass">
        <template v-if="field.label" slot="label">
            <span v-html="field.label" />
            <mandatory-flag v-if="mandatory" />
            <info-button v-if="field.info">
                <div v-html="field.info" />
            </info-button>
        </template>

        <template slot="description">{{field.description}}</template>

        <ckeditor ref="input" :id="toID(field.name)" :editor="editor" v-model="value" :config="editorConfig" :required="mandatory" :readonly="readonly" :placeholder="field.placeholder" @paste="onPaste" :rows="field.minRows" :max-rows="field.maxRows" :maxlength="field.maxLength" />

        <b-form-invalid-feedback>
            <div v-if="field.mandatoryMessage && !(value)" v-html="field.mandatoryMessage" />
            <div v-else-if="field.formatMessage && !customError" v-html="field.formatMessage" />
            <div v-else-if="field.validationMessage && customError" v-html="field.validationMessage" />
        </b-form-invalid-feedback>
    </b-form-group>

</template>

<script>
import Field from '@/shared/utils/Field'
import EvaluateCondition from '@/shared/utils/EvaluateCondition'

import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'FormHtml',

    components: {
        ckeditor: CKEditor.component
    },

    props: {
        field: Object,
        data: Object,
        labelClass: String
    },

    data() {
        return {
            customError: false,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: ['heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
            }
        };
    },

    computed: {
        value: {
            get: function () {
                return Field.getValue(this.field.name, this.data, this.field.defaultValue)
            },

            set: function (newValue) {
                if (newValue == "" || newValue == null) {
                    //Field.clearField(this.field.name, this.data, this.$delete); // JK Exception in Ckeditor
                    Field.setValue(this.field.name, "", this.data, this.$set)
                }
                else {
                    Field.setValue(this.field.name, newValue, this.data, this.$set)
                }

                this.validate();
            }
        },

        readonly: function () {
            if (this.field.readonly) {
                return true;
            }

            if (this.field.readonlyIf) {
                return EvaluateCondition.evaluate(this.field.readonlyIf, this.data);
            }

            return false;
        },

        mandatory: function () {
            if (this.field.mandatory) {
                return true;
            }

            if (this.field.mandatoryIf) {
                return EvaluateCondition.evaluate(this.field.mandatoryIf, this.data);
            }

            return false;
        }

    },

    mounted: function () {
        if (this.field.defaultValue) {
            this.value = this.field.defaultValue; // Store default value to the data
        }

        window.editor = this.editor;
    },

    methods: {
        validate() {
            if (this.field.validIf) {
                var validity = this.$refs.input.validity;

                //console.log(validity);
                if (!validity.typeMismatch && !validity.patternMismatch) {
                    var valid = EvaluateCondition.evaluate(this.field.validIf, this.data);
                    if (!valid) {
                        //document.getElementById(this.toID(this.field.name)).setCustomValidity(this.field.validationMessage);
                        this.customError = true;
                        return false;
                    }
                }
            }

            //document.getElementById(this.toID(this.field.name)).setCustomValidity("");
            this.customError = false;
            return true;
        },

        onPaste(evt) {
            if (this.field.pasteDisabled) {
                evt.preventDefault();
            }
        }

    }
}
</script>

<style lang="scss">
    

    .ck.ck-balloon-panel {
        z-index: 9999;
    }
</style>
