<template>

    <b-form-group :label-for="toID(field.name)" :label-class="labelClass">
        <loading loader="dots" :active.sync="isLoading" :is-full-page="true"></loading>

        <template v-if="field.label" slot="label">
            <span v-html="field.label" />
            <mandatory-flag v-if="field.mandatory" />
            <info-button v-if="field.info">
                <div v-html="field.info" />
            </info-button>
        </template>

        <template slot="description">{{field.description}}</template>

        <b-form-file ref="fileInput" :id="toID(field.name)" v-model="file" :accept="accept" :class="{ invalid: required || customError , 'custom-error': customError }" :required="required || customError" multiple @input="upload" @click.native="clear" :placeholder="field.placeholder" :drop-placeholder="field.placeholder" />

        <b-form-invalid-feedback>
            <div v-if="error && customError">{{error}} <a href="#" @click.prevent="clear()"><i class="fas fa-times"></i></a></div>
            <div v-else-if="required && field.mandatoryMessage" v-html="field.mandatoryMessage" />
        </b-form-invalid-feedback>

        <b-row align-h="start" :id="toID(field.name)+'_files'" class="files">
            <b-col v-for="file in files" :key="file.id" cols="auto" class=file>
                <i v-if="file.signed" class="fas fa-signature mr-1 signed" title="signiert"></i>
                <a v-if="field.showLink" :href="api+'/file/'+file.id" target="new">{{file.fileName}} </a>
                <template v-else>{{file.fileName}} </template>
                <a href="#" @click.prevent="remove(file.id)"><i class="fas fa-times"></i></a>
            </b-col>
        </b-row>
    </b-form-group>

</template>

<script>
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay';
import i18n from '@/shared/i18n'

import uploadService from '@/services/upload'
import EvaluateCondition from '@/shared/utils/EvaluateCondition'

export default {
    name: 'FormUpload',

    props: {
        field: Object,
        data: Object,
        attachments: Object,
        labelClass: String
    },

    components: { Loading },

    data() {
        return {
            customError: false,
            error: "ERROR",
            file: null,
            isLoading: false
        };
    },

    computed: {
        ...mapGetters(['api', 'uuid', 'cluster', 'config', 'validation']),

        files: function () {
            return this.attachments[this.field.name]
        },

        required: function () {
            if (this.field.mandatory) {
                return (this.files == undefined || this.files.length == 0);
            }

            if (this.field.mandatoryIf) {
                return EvaluateCondition.evaluate(this.field.mandatoryIf, this.data) && (this.files == undefined || this.files.length == 0);
            }

            return false;
        },

        accept() {
            if (this.field.allowedFileExtensions) {
                return this.field.allowedFileExtensions.toString();
            }

            return "";
        }
    },

    watch: {
        validation(newValue) {
            // Validation start
            if (newValue) {
                this.clear();
            }
        }
    },

    methods: {
        upload(fileList) {
            this.customError = false;

            if (!fileList.length || !this.validate(fileList)) {
                return;
            }

            this.isLoading = true;
            console.log("Uploading " + fileList.length + " files:");
            const formData = new FormData();

            // append the files to FormData
            Array.from(Array(fileList.length).keys()).map(x => {
                formData.append(this.field.name, fileList[x], fileList[x].name);
                console.log("Uploading " + fileList[x].name);
            });

            formData.append("cluster", this.cluster);
            formData.append("uuid", this.uuid);

            // save it
            uploadService.upload(formData).then(result => {
                if (result.error) {
                    this.setError(result.error)
                }

                this.setAreaAttachments(this.field.name, result.data)
                this.isLoading = false;
            })
                .catch(error => {
                    this.isLoading = false;
                    this.showError(error.message)
                });

            this.clear();

            // background data save on upload
            if (this.config.saveOnUpload) {
                this.$emit('save', false);
            }
        },

        clear() {
            this.customError = false;
            this.$refs.fileInput.reset();
        },

        remove(id) {
            this.isLoading = true;
            this.customError = false;

            uploadService.delete(id).then(result => {
                this.setAreaAttachments(this.field.name, result.data)
                this.isLoading = false;
            })
                .catch(error => {
                    this.isLoading = false;
                    this.showError(error.message)
                });

            // background data save on upload
            if (this.config.saveOnUpload) {
                this.$emit('save', false);
            }
        },

        setAreaAttachments(area, attachments) {
            if (attachments.length > 0) {
                this.$set(this.attachments, area, attachments)
            }
            else {
                this.$delete(this.attachments, area)
            }
        },

        validate(fileList) {
            this.customError = false;

            for (var file of fileList) {
                //name, size, type
                console.log(file.name + " " + file.size + " " + file.type);

                // Check empty file
                if (file.size == 0) {
                    return this.setError("FileIsEmpty")
                }

                // Check allowed extensions
                if (this.field.allowedFileExtensions) {
                    var extension = file.name.indexOf(".") > 0 ? file.name.split('.').pop().toLowerCase() : "no extension";
                    if (this.field.allowedFileExtensions.indexOf("." + extension) < 0) {
                        return this.setError("WrongExtension");
                    }
                }

                // Check file count
                const maxUploads = this.field.maxUploads ? this.field.maxUploads : 99; // Default 99
                if (this.files != undefined && this.files.length >= maxUploads) {
                    return this.setError("TooManyFiles");
                }

                // Check file size
                const maxUploadSize = this.field.maxUploadSize ? this.field.maxUploadSize * 1024 * 1024 : 15 * 1024 * 1024; // Default 15MB
                if (file.size > maxUploadSize) {
                    return this.setError("MaxSizeExceeded");
                }

                // Check file sizes for whole upload area
                const maxButtonSize = this.field.maxButtonSize ? this.field.maxButtonSize * 1024 * 1024 : 25 * 1024 * 1024; // Default 25MB

                if (this.files) {
                    var total = 0;
                    this.files.forEach(f => total += f.fileSize);

                    if (total + file.size > maxButtonSize) {
                        return this.setError("MaxSizePerButtonExceeded");
                    }
                }
            }

            return true;
        },

        setError(error) {

            this.customError = true;

            if (error.includes("error.")) {
                this.error = i18n.t(error);
            }
            else {
                this.error = error.includes(" ") ? error : i18n.t("error." + error);
            }

            return false;
        }

    }
}
</script>

<style lang="scss">
    .files {
        padding-top: 5px;
    }

    .file {
        white-space: nowrap;
    }

    .custom-file-input:lang(de) ~ .custom-file-label::after {
        content: "Auswählen";
    }

    /* Invalid feedback for uploads */
    .was-validated .b-form-file.invalid ~ .invalid-feedback,
    .b-form-file.custom-error ~ .invalid-feedback {
        display: block;
    }
</style>
