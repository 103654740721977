<template>
    <div>
        <form ref="form" @submit.stop.prevent="submitForm" novalidate v-bind:class="{ 'was-validated': validated }">
           <form-page :page="form" :buttons="buttons.buttons" :data="data" @enter="submitFormEnter" />
        </form>

        <div v-if="showData" role="tablist" class="mt-3">
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block href="#" v-b-toggle.accordion-1 size="sm">Data ({{Object.keys(data).length}})</b-button>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <pre class="mt-2">{{data}}</pre>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
    import FormPage from '@/shared/components/forms/FormPage'

    import buttons from '@/config/Buttons.json'

    export default {
        name: 'JsonForm',

        props: {
            form: Object, // Page JSON configuration
            data: Object,  // Form data
            submitOnEnter: { // If pressing Enter should submit form
                type: Boolean,
                default: false
            }
        },

        components: { FormPage },

        data() {
            return {
                validated: false,
                buttons,
                showData: false
            };
        },

        methods: {
            submitForm() {
                if (this.$refs.form.checkValidity() === false) {
                    this.validated = true;
                    return;
                }

                this.$emit("submit");
            },

            submitFormEnter() { 
                if (this.submitOnEnter) {
                    this.submitForm();
                }
            }
        }
    }
</script>

<style lang="scss">
    pre {
        max-height: 300px;
        overflow: auto;
    }
</style>