<template>
    <div class="group-field" :class="{invalid}"> 
        <template v-for="(group, index) in groups" >
            <form-group :group="group" :key="field.name+'_group_'+index" v-on="$listeners" :data="data" :attachments="attachments" />

            <form-button v-if="field.removeButton && field.minRows<groups.length" :button="config.buttons[field.removeButton]" @button="buttonClick($event, index)" :key="field.name+'_remove_'+index" />
        </template>

        <template v-if="field.addButton && (field.maxRows==undefined || field.maxRows>groups.length)">
            <hr v-if="field.group.hr" />
            <h1 v-if="field.group.title">
                {{ field.group.title }} {{groups.length>0 ? groups.length+1 : ""}}
                <form-button :button="config.buttons[field.addButton]" @button="buttonClick" />
            </h1>
        </template>

        <input :id="toID(field.name)" class="group-field-validation" type="text" />
        <b-form-invalid-feedback class="group-field" >
            {{field.validationMessage}} 
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import EvaluateCondition from '@/shared/utils/EvaluateCondition'
    import Field from '@/shared/utils/Field'
    import FormButton from '@/shared/components/forms/FormButton'

    export default {
        name: 'FormGroupField',

        props: {
            field: Object,
            data: Object,
            attachments: Object
        },

        components: {
            FormGroup: () => import('@/shared/components/forms/FormGroup'), // https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
            FormButton
        },

        data() {
            return {
            };
        },

        computed: {
            ...mapGetters(['config', 'uuid']),

            groups: function() {
                var groups=[]

                var items=Field.getValue(this.field.name, this.data, [])
                var count=Math.max(items.length, this.field.minRows);

                for (var i=0; i<count; i++) {
                    groups.push(this.getGroup(i))
                }

                return groups;
            },

            invalid: function() {
                var input=document.getElementById(this.toID(this.field.name));

                if (this.field.validIf) {
                    var valid=EvaluateCondition.evaluate(this.field.validIf, this.data);
                    if (!valid) {
                        if (input) {
                            input.setCustomValidity(this.field.validationMessage);
                        }

                        return true;
                    }
                    
                }

                if (input) {
                    input.setCustomValidity("");
                }

                return false;
            }, 
        },

        mounted() {
            this.invalid;
        },

        methods: {
            getGroup(index) {
                var group = JSON.parse(JSON.stringify(this.field.group)); // clone

                if (group.title && this.field.maxRows>1) {
                    group.title = group.title + " " + (index + 1);
                }

                for (var fieldName in group.fields) {
                    var field = group.fields[fieldName]
                    field.name = this.field.name + "[" + index + "]." + field.name

                    if (field.validIf) {
                        this.updateCondition(field.validIf, index)
                    }

                    if (field.mandatoryIf) {
                        this.updateCondition(field.mandatoryIf, index)
                    }
                }

                for (var row of group.rows) {
                    if (row.visibleIf) {
                        this.updateCondition(row.visibleIf, index)
                    }

                    for (var col of row.cols) {
                        if (col.visibleIf) {
                            this.updateCondition(col.visibleIf, index)
                        }
                    }
                }

                return group;
            },

            /**
             * Updates condition to use with index. Replaces [] to index parameter and add array name
             */
            updateCondition(condition, index) {
                if (condition.fieldName && condition.fieldName.includes("[]")) {
                    condition.fieldName=condition.fieldName.replace("[]", this.field.name+"["+index+"]")
                }

                if (condition.fieldValue.toString().startsWith("=") && condition.fieldValue && condition.fieldValue.includes("[]")) {
                    condition.fieldValue=condition.fieldValue.replace("[]", this.field.name+"["+index+"]")
                }

                if (condition.and) {
                    for (var andCondition of condition.and) {
                        this.updateCondition(andCondition, index)
                    }
                }

                if (condition.or) {
                    for (var orCondition of condition.or) {
                        this.updateCondition(orCondition, index)
                    }
                }
            },

            buttonClick(button, params) {
                switch(button.action) {
                    case 'add': this.add(); break;
                    case 'remove': this.remove(params); break;
                }

                this.$emit('button', button); // To send event to parent to execute save if defined
            },

            add() {
                var items=Field.getValue(this.field.name, this.data, [])
                items.push({});
                Field.setValue(this.field.name, items, this.data, this.$set)
            },

            remove(index) {
                var group=this.getGroup(index);

                // Remove uploads
                for (var fieldName in group.fields) {
                    var field=group.fields[fieldName]
                    if (field.type=='U') {
                        Field.deleteUpload(field.name, this.uuid, this.attachments, this.$delete);
                    }
                }

                var items=Field.getValue(this.field.name, this.data, [])
                items.splice(index, 1);
            }
        }
    }
</script>

<style lang="scss">

    /* Invalid feedback GroupField */
    .was-validated .group-field.invalid .group-field.invalid-feedback {
        display: block;
        margin-top: 0.5rem;
    }

    .group-field-validation {
        display: inherit;
        height: 0px;
        border: none;
        padding: 0;
        margin: 0;
    }
    
</style>
