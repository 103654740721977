<template>
    <div class="session-countdown text-right" :class="{expiring: diff<60 && diff>0}">
        in {{ minutes | twoDigits }}:{{ seconds | twoDigits }}
    </div>
</template>

<script>
    let interval = null;
    import { mapGetters } from 'vuex'

    export default {
        name: 'SessionCountdown',

        props: {
        },

        data() {
            return {
                time: Math.trunc((new Date()).getTime() / 1000),
                diff: 0
            }
        },

        mounted() {
            interval = window.setInterval(() => {
                this.time = Math.trunc((new Date()).getTime() / 1000);
                this.diff = this.expiration - this.time;
                if (this.diff <= 0) {
                    this.diff = 0;
                    // Remove interval
                    clearInterval(interval);
                    this.$emit("expired");
                }        
            },1000);
        },

        computed: {
            ...mapGetters(['expiration']),

            seconds() {
                return Math.trunc(this.diff) % 60
            },

            minutes() {
                return Math.trunc(this.diff / 60) % 60
            }
        },

        destroyed() {
            clearInterval(interval);
        }
    }
</script>

<style lang="scss">
</style>
