<template>
    <page-layout :loading="isLoading">
        <b-row class="align-items-center">
            <h1 class="col-auto">
                Finanzierungen

                <b-dropdown dropright size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                        <i class="fas fa-share-square"></i>
                    </template>

                    <b-dropdown-item :href="api+'/csv/financing/open'">CSV aktive Finanzierungen</b-dropdown-item>
                    <b-dropdown-item :href="api+'/csv/financing/all'">CSV alle Finanzierungen</b-dropdown-item>
                </b-dropdown>
            </h1>

            <div class="col-auto ml-auto mb-2 text-nowrap">
                SORTIEREN <b-form-select class="sort" v-model="sort" :options="[{ value: 'date', text: 'nach Restlaufzeit (kürzeste zuerst)' }, { value: 'amount', text: 'nach Betrag absteigend' }]"></b-form-select>
            </div>
        </b-row>

        <b-row>
            <div class="col-lg-8 col-xl-6">
                <b-card>
                    <b-row class="align-items-center">
                        <div class="col-sm-7">
                            Sie interessieren sich für eine Finanzierung bei der Kommunalkredit?
                        </div>

                        <div class="col-sm-5 mt-2 mt-sm-0">
                            <b-button to="/request" size="sm" variant="primary" class="w-100">ANFRAGEN</b-button>
                        </div>
                    </b-row>
                </b-card>
            </div>
        </b-row>

        <div v-for="data in sorted" :key="data.number">
            
            <b-row>
                <h2 class="col-12 mt-3">{{data.number}}</h2>
            </b-row>

            <div class="row cards">
                <div class="card-col col-sm-6 col-lg order-1">
                    <div class="card">
                        <div class="card-body align-items-center d-flex justify-content-center">
                            <div class="text-center">
                                <b>Aushaftender Saldo</b>
                                <big-amount :amount="data['SAPDarlehen - Restkapital']" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-col col-sm-12 col-lg-7 order-2 order-sm-3 order-lg-2">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <h2 class="col-md-12">
                                    {{data.description}} 
                                    <a href="#" @click.prevent="editDescription(data)" ><i class="fas fa-edit"></i></a>
                                </h2>

                                <div class="col-md-4">
                                    <div class="label">Finanzierungsvolumen</div><b>{{data['SAPDarlehen - Nominale'] | formatNumber}} EUR</b>
                                </div>

                                <div class="col-md-4">
                                    <div class="label">Aktueller Zinssatz</div><b>{{data['SAPDarlehen - Akt. Zinssatz'] | formatNumber(1)}} % p.a.</b>
                                </div>

                                <div class="col-md-4">
                                    <div class="label">Laufzeit bis</div><b>{{data['SAPDarlehen - Laufzeitende'] | formatDate}}</b>
                                </div>
                            </b-row>

                            <b-row class="mt-2 align-items-center">
                                <div class="col-md-4">
                                    <div class="label">
                                        Noch nicht abgerufenes Kapital
                                        <info-button>
                                            Kapitalbetrag, der noch zur Gänze oder in Teilbeträgen ausgenützt werden kann.
                                        </info-button>
                                    </div>
                                    <b>{{data['SAPDarlehen - Auszahlungsverpfl'] | formatNumber}} EUR</b>
                                </div>

                                <div class="col-md-4">
                                    <div class="label">
                                        Bereits ausgezahltes Kapital
                                        <info-button>
                                            Betrag, der auf diesem Konto bereits in Anspruch genommen wurde.
                                        </info-button>
                                    </div>
                                    <b>{{data['SAPDarlehen - Valut.Kapital'] | formatNumber}} EUR</b>
                                </div>

                                <div class="col-md-4">
                                    <b-button :to="{ name: 'finance-documents', params: { id: data.egoId } }" size="sm" variant="primary" class="w-100">DOKUMENTE</b-button>
                                </div>
                            </b-row>
                        </div>
                    </div>
                </div>

                <div class="card-col col-sm-6 col-lg order-3 order-sm-2 order-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <h2 class="col-md-12">
                                    SEPA-Lastschriftmandat 
									<a v-if="data['SAPDarlehen - Einz. Kz.'] !== 'N'" :href="'/online/files/Documents/SEPA_Lastschrift-Mandat.pdf'">
										<i class="fas fa-edit"></i>
									</a>
                                </h2>
                                <template v-if="data['SAPDarlehen - Einz. Kz.']!=='N'">
                                    <div class="col-md-12">
                                        <div class="label">BIC</div>
                                        <b>{{data['SAPDarlehen - Einz. SWIFT']}}</b>
                                    </div>

                                    <div class="col-md-12 mt-2">
                                        <div class="label">IBAN</div>
                                        <b>{{data['SAPDarlehen - Einz. IBAN']}}</b>
                                    </div>
                                </template>
                                <template v-else>
                                    <small class="col-md-12 small-text">
                                        <b>Derzeit besteht für dieses Konto kein SEPA-Lastschriftmandat</b>
                                    </small>
                                    <div class="col-md-12 mt-3">
                                        <b-button :href="'/online/files/Documents/SEPA_Lastschrift-Mandat.pdf'" size="sm" variant="primary" class="w-100">
											JETZT BEANTRAGEN
										</b-button>
                                    </div>
                                </template>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="modal-description" ok-title="SPEICHERN" cancel-title="abbrechen" @ok="saveDescription">
            <template v-slot:modal-title>
                <b>Kontobezeichnung ändern</b>
            </template>

            <form ref="form" @submit.stop.prevent novalidate v-bind:class="{ 'was-validated': validated }">
                <b-row>
                    <form-page :page="descriptionForm" :data="selected" class="col-12" />
                </b-row>
            </form>
        </b-modal>        
    </page-layout>
</template>

<script>
    import PageLayout from '@/layouts/KontomanagerLayout.vue'
    import BigAmount from '@/components/BigAmount.vue'
    import FormPage from '@/shared/components/forms/FormPage'

    import descriptionForm from '@/config/AccountDescriptionForm.json'
    import productService from '@/services/product'
    import { mapGetters } from 'vuex'

    export default {
        name: 'FinancePage',

        components: {
            PageLayout, BigAmount, FormPage
        },

        data() {
            return {
                sort: 'date',
                list: [],
                selected: {},
                validated: false,
                isLoading: true,
                descriptionForm
            };
        },

        computed: {
            ...mapGetters(['user', 'api']),
            sorted: function() {
                return this.list.slice(0).sort( this.sort=='date' ? this.compareByDate : this.compareByAmount );
            }
        },

        mounted: function() {
            productService.getFinancing().then(data => {
                this.list=data;
                this.isLoading=false;
            }).catch((error) => {
                this.isLoading=false;
                this.showError(error.message)
            })
        },

        methods: {
            editDescription(data) {
                this.selected=data;
                this.selected.newDescription="";
                this.validated = false;

                this.$bvModal.show('modal-description');
            },

            saveDescription(bvModalEvt) {
                if (this.$refs.form.checkValidity() === false) {
                    this.validated = true;
                    bvModalEvt.preventDefault();
                    return;
                }

                this.isLoading=true;

                productService.updateProductDescription(this.selected.egoId, this.selected.newDescription).then(() => {
                    this.selected.description=this.selected.newDescription;
                    this.isLoading=false;
                }).catch((error) => {
                    this.isLoading=false;
                    this.showError(error.message)
                })
            },

            compareByDate(a, b) {
                a=a['SAPDarlehen - Laufzeitende'];
                b=b['SAPDarlehen - Laufzeitende'];

                if (a<b) {
                    return -1;
                }

                if (a>b) {
                    return 1;
                }

                return 0;
            },

            compareByAmount(a, b) {
                a=a['SAPDarlehen - Nominale'];
                b=b['SAPDarlehen - Nominale'];

                if (a<b) {
                    return 1;
                }

                if (a>b) {
                    return -1;
                }
                
                return 0;
            }


        }
    }
</script>

<style lang="scss">    
</style>