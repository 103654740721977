<template>
    <page-layout :loading="isLoading">
        <template #menu>
            <div class="col-auto ml-auto order-3 order-md-2">
                <a href="mailto:kundenservice@kommunalkreditdirekt.at?subject=Pers%F6nliche%20Anfrage%20aus%20Online%20Banking" class="row align-items-center">
                    <div class="col-auto pl-0 pr-2 text-right">PERSÖNLICHE<br>ANFRAGE</div>
                    <i class="far fa-comments fa-2x col-auto px-0"></i>
                </a>
            </div>

            <div class="col-auto ml-3 p-0 order-2">
                <b-link to="/reminder" class="row align-items-center mr-3">
                    <div class="col-auto text-right pr-2">PASSWORT<br>VERGESSEN</div>
                    <i class="far fa-comments fa-2x col-auto p-0"></i>
                </b-link>
            </div>
        </template>

        <div class="row justify-content-center flex-grow-1">
            <div class="col-md-10 col-lg-8 col-xl-7">
                <div>
                    <h1 class="text-center mb-0">Herzlich willkommen bei KOMMUNALKREDIT DIREKT!</h1>
                    <p class="text-center">Unser Angebot für Gemeinden und öffentlichkeitsnahe Unternehmen</p>
                </div>
                <div class="row card mx-0 px-3">
                    <div>
                        <json-form v-if="!loggedIn" :form="form" :data="data" @submit="submitForm" submitOnEnter />
                        <div v-else class="mt-3">
                            <p class="text-danger">Sie sind bereits eingeloggt.<br>Bitte melden Sie sich vor einem erneuten Login ab!</p>
                            <b-link class="btn btn-primary" @click="logout">Abmelden</b-link>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="card mx-0 px-3 mt-3">
                        <div>Sind Sie Privatkunde bei KOMMUNALKREDIT INVEST?</div>
                        <div>Nutzen Sie bitte den Login auf der jeweiligen Website:</div>
                        <div class="mt-2">Für unsere Kunden in Österreich: <a href="https://www.kommunalkreditinvest.at"><u>www.kommunalkreditinvest.at</u></a></div>
                        <div>Für unsere Kunden in Deutschland: <a href="https://www.kommunalkreditinvest.de"><u>www.kommunalkreditinvest.de</u></a></div>
                    </div>
                </div>
            </div>
        </div>
    </page-layout>
</template>

<script>
import PageLayout from '@/layouts/KontomanagerLayout.vue'
import JsonForm from '@/components/JsonForm'

import form from '@/config/LoginForm.json'
import buttons from '@/config/Buttons.json'

import authenticationService from '@/services/authentication'
import userService from '@/services/user'

export default {
    name: 'LoginPage',
    components: { PageLayout, JsonForm },

    data: function () {
        return {
            data: {},
            isLoading: true,
            loggedIn: false,
            form, buttons
        }
    },

    mounted() {
        authenticationService.setCSRFToken().then(() => {
            userService.getUser().then((response) => {
                if (response.userId) {
                    console.log("User already logged!")
                    this.loggedIn = true;
                }
                this.isLoading = false;
            }).catch((error) => {
                this.loggedIn = false;
                this.isLoading = false;
                console.log(error)
            })
        }).catch((error) => {
            this.isLoading = false;
            this.showError(error.message)
        })

        this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'updateUserData':
                    this.isLoading = false;

                    if (mutation.payload.changePassword) {
                        this.$router.push({ name: 'password-new' })
                    }
                    else {
                        var redirect = this.$route.query.redirect
                        this.$router.push({ name: redirect != undefined ? redirect : 'overview' })
                    }

                    break;
            }
        })
    },

    methods: {
        submitForm() {
            this.isLoading = true;

            authenticationService.authenticate(this.data).then(() => {
                this.isLoading = false;
                this.$store.dispatch('getUserData')
            }).catch((error) => {
                this.isLoading = false;
                this.showError(error.message)
                this.data = {}
            })
        },

        logout() {
            userService.logout().then(() => {
                this.$store.dispatch('logout');
                this.$router.push({ name: 'logout' });
            });
        }

    }

}
</script>

<style lang="scss" scoped>
</style>
